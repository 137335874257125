.loaderconstiner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loader {
  width: 12.5rem;
}
.loader-para {
  margin-top: 2rem;
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: .8rem;
  font-style: italic;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
}
.heading {
  font-size: 16px;
  padding-left: 5px;
  color: #fff;
  margin-top: 15px;
}
.no-scroll {
  overflow: hidden;
}
