:root {
  --ifl--primary: #fbd323;
  --ifl--primary2: #d8edfe;
  --ifl--white: #fff;
  --ifl--accent: #263643;
  --ifl--text: rgba(0, 0, 0, 0.7);
  --ifl--dark: #000;
  --ifl--grey: #f8f8f8;
  --ifl--primary3: #748494;
  --ifl--secondary: #324452;
  --ifl--whatsapp: #219653;
  --nav-bar-height: 100px;
}

body,
html {
  font-family: "Montserrat", sans-serif;
  font-size: 1rem;

  // &:has(.theme-dark) {
  //   background: linear-gradient(60.07deg, #01206D 15.98%, #020A1F 79.88%), #FFFFFF;
  // }
  // &:has(.theme-light) {
  //   background: var(--ifl--grey);
  // }
}

.body-wrapbox {
  min-height: 100vh;
}

.theme-light {
  height: calc(100vh + var(--nav-bar-height));
  background: var(--ifl--grey);
}

.theme-dark {
  background: linear-gradient(60.07deg, #01206d 15.98%, #020a1f 79.88%), #ffffff;
  .navbar__custom {
    background: linear-gradient(60.07deg, #01206d 15.98%, #020a1f 79.88%);
  }
  .grad11 {
    background: linear-gradient(60.07deg, #01206d 15.98%, #020a1f 79.88%), #ffffff;
  }
  .need--helptxt,
  .call--text,
  .page-not-found-wrapper {
    color: var(--ifl--white);
  }
}
.theme-exam {
  background: #fafcff;
}
.theme-idl {
  background: #fff;
}

.modal- {
  &wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    background: rgba(50, 68, 82, 0.9);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &container {
    position: relative;
    min-width: 400px;
    max-width: 80vw;
    min-height: 200px;
    border: 1px solid var(--ifl--primary);
    border-radius: 1rem;
    background: #141c2b;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    padding: 1.25rem;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 480px) {
      // Adjust the breakpoint as needed
      min-width: 360px; // Reduce the width for smaller screens
    }
  }

  &title {
    margin: 0;
    width: 100%;
    text-align: center;
    color: var(--ifl--primary);
    background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    font-weight: 600;
    font-size: 16px;
    line-height: 1.3;
  }

  &close {
    position: absolute;
    top: 10px;
    right: 10px;

    background: none;
    outline: none;
    border: none;
  }

  &goback {
    text-align: center;
  }
}

.reset-password-form {
  max-width: 400px;
}

.navbar__custom {
  height: var(--nav-bar-height);
  background-color: var(--ifl--white);
  padding: 0 1.5rem;
  box-shadow: 0 4px 77px rgba(0, 0, 0, 0.051);
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 3;
  display: flex;
  align-items: center;
}
.need--helptxt {
  font-size: 0.875rem;
  color: var(--ifl--text);
  margin: 0;
}
.call--text {
  font-weight: 700;
  color: var(--ifl--dark);
  display: inline-block;
  text-decoration: none;
  font-size: 1.25rem;
}
.main__wrapper {
  padding: 1.25rem 1.875rem 1rem 2.5rem;
  display: flex;
  gap: 5%;
}
.aside__wrapper {
  width: 180px;
  min-height: 460px;
  border-radius: 1rem;
  background-color: var(--ifl--white);
  flex: 0 0 auto;
  display: flex;
  height: max-content;
  flex-direction: column;
  position: -webkit-sticky;
  position: sticky;
  top: 110px;
}

.main__content__wrapper {
  width: 100%;
}
.aside__wrapper ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.aside__wrapper ul li a {
  padding: 0.785rem 1.5rem;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  width: 100%;
  font-weight: 500;
  color: var(--ifl--secondary);
  font-size: 0.875rem;
}
.aside__wrapper ul li a span {
  line-height: 0;
}
.aside__wrapper ul li:has(a.active) {
  background-color: var(--ifl--primary2);
  color: #273743;
}
.aside__wrapper ul li a img {
  margin-right: 0.65rem;
}
.help__desk {
  margin-top: auto;
  padding-bottom: 1.25rem;
}
.bar2 {
  display: block;
  width: auto;
  font-size: 0.6rem;
  font-weight: 700;
  color: var(--ifl--primary3);
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  padding-left: 1rem;
}
.bar2::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 50%;
  transform: translateY();
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--ifl--primary3);
}
.bar2 span {
  display: inline-block;
  padding: 0rem 0.5rem 0rem 0.25rem;
  background-color: var(--ifl--white);
  position: relative;
  letter-spacing: 0.9px;
}
.tel--text {
  font-weight: 700;
  font-size: 1rem;
  text-decoration: none;
  text-align: start;
  display: inline-flex;
  color: var(--ifl--primary3);
  align-items: center;
}
.tel--text svg {
  margin-right: 0.5rem;
}
.hep__desk--text {
  color: var(--ifl--text);
  font-size: 12px;
  margin: 0;
  line-height: 1.3;
  margin-top: 0.15rem;
}
.dashboard--heading {
  font-weight: 600;
  margin: 0;
  color: var(--ifl--dark);
  line-height: 1.2;
  font-size: 2rem;
}
.dashboard--text {
  color: var(--ifl--text);
  font-size: 0.875rem;
  line-height: 1.3;
  font-weight: 400;
  margin: 0;
}
.form__select--label {
  font-weight: 500;
  color: var(--ifl--accent);
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  display: block;
}
.form__select--label sub {
  color: #9b0a0a;
  font-size: 0.75rem;
  bottom: 0;
  margin-left: 0.1rem;
}
.custom--select {
  border: 1px solid #736b42;
  border-radius: 4px;
  min-width: 255px;
  display: block;
  height: auto;
  min-height: 45px;
  color: #fff;
  font-weight: 500;
}
.nice-select:hover {
  border-color: #94a1b1;
}
.nice-select:after {
  border-color: var(--ifl--accent);
  width: 7px;
  height: 7px;
  right: 20px;
}
.nice-select .list {
  filter: drop-shadow(0px 2px 77px rgba(0, 0, 0, 0.1));
  border-radius: 8px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: none;
}
.nice-select .option {
  color: #556575;
  height: auto;
  min-height: auto;
  line-height: initial;
  padding: 12px;
}
.simplebar-track.simplebar-vertical {
  width: 5px;
}
.simplebar-scrollbar:before {
  background-color: #646e76;
  top: 3px;
  bottom: 3px;
  left: 0;
  right: 1px;
}
.count--text {
  color: var(--ifl--dark);
  font-size: 1rem;
  margin: 0;
  font-weight: 500;
}
.searchbar--box input[type="search"] {
  border: 0;
  outline: none;
  background: transparent;
  font-size: 1rem;
}
.searchbar--box input[type="search"]::placeholder {
  color: rgba(0, 0, 0, 0.5);
}
.custom__dataTable table th {
  background-color: #f3faff;
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1.5rem 1.15rem;
  border: 0;
}
.custom__dataTable table td {
  padding: 1.5rem 1.15rem;
  padding-right: 2.8rem;
  background-color: var(--ifl--white);
  border-bottom: 1px solid #ededed;
  color: var(--ifl--dark);
  font-size: 1rem;
  font-weight: 500;
}
/* .custom__dataTable table tbody tr:last-child td {
    border-bottom: 0;
} */
.custom__dataTable table th::after,
.custom__dataTable table th::before {
  bottom: 50%;
  transform: translateY(-50%);
  color: #000;
}
.custom__dataTable table th::after {
  right: 17px !important;
}
.custom__dataTable table th::before {
  right: 25px !important;
}
.user__content {
  display: grid;
  grid-template-columns: 32px 1fr;
  grid-gap: 0.5rem;
  align-items: center;
}
.user__content--pic {
  display: inline-block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}
.user__content--pic > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.user__content--name {
  color: var(--ifl--dark);
  font-size: 1rem;
  font-weight: 500;
  display: block;
}
.status-active {
  text-decoration: underline;
  display: inline-block;
  color: var(--ifl--whatsapp);
  font-weight: 700;
}
td .custom--dropdown:hover {
  background-color: #e7e7e7;
  border-radius: 50%;
}
td .custom--dropdown {
  display: inline-block;
  margin-left: 1.5rem;
  width: 17px;
  height: 35px;
  width: 35px;
  height: 35px;
  text-align: center;
  border-radius: 8px;
}
span.user__content {
  max-width: 250px;
}
.custom__dataTable table td:last-child.w--100 .dropdown {
  margin-left: 5px;
}
td .custom--dropdown .dropdown-toggle {
  background: transparent !important;
  box-shadow: none !important;
  border: 0;
  padding: 5px;
  width: auto;
  text-align: center;
}
td .custom--dropdown .dropdown-toggle::after {
  display: none;
}
.custom--dropdown .dropdown-menu {
  border: 0.5px solid #efefef;
  box-shadow: 0px 4px 77px rgba(0, 0, 0, 0.051);
  border-radius: 16px;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
}
.custom--dropdown .dropdown-menu .dropdown-item {
  color: #556575;
  height: auto;
  min-height: auto;
  line-height: initial;
  padding: 12px;
  font-size: 0.875rem;
  font-weight: 500;
}
.custom--dropdown .dropdown-menu .dropdown-item:active,
.custom--dropdown .dropdown-menu .dropdown-item:hover,
.custom--dropdown .dropdown-menu .dropdown-item:focus {
  background-color: #f6f6f6 !important;
}
.w--100 {
  width: 120px;
  max-width: 120px;
}
.custom__dataTable .dataTables_wrapper {
  border: 1px solid #e7e8f1;
  box-shadow: 0px 4px 30px rgb(0 0 0 / 5%);
  border-radius: 8px;
  overflow: hidden;
  background-color: var(--ifl--white);
}
.custom__dataTable table {
  margin: 0 !important;
}
.searchbar--box {
  border: 1px solid #bcbcbc;
  background: var(--ifl--white);
  width: 255px;
  padding: 10px;
  border-radius: 5px;
  display: inline-grid;
  grid-gap: 0.5rem;
  grid-template-columns: 25px 205px;
}
.searchbar--box--svg {
  opacity: 0.5;
}
.btndt {
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ifl--primary);
  border-radius: 5px;
}
.dataTables_filter {
  display: none;
}
.custom__dataTable {
  position: relative;
}
/* .dataTables_wrapper .dt-row {
    margin-bottom: 1rem;
} */
.dataTables_wrapper .dt-row + .row {
  margin: 0;
  padding: 30px 10px 30px 15px;
  align-items: center;
  justify-content: center;
}
.dataTables_wrapper .dt-row + .row > [class^="col"] {
  width: auto;
}
.dataTables_wrapper .dt-row + .row > [class^="col"] + [class^="col"] {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  padding: 0;
}
.dataTables_wrapper .dt-row + .row > [class^="col"] + [class^="col"] ul.pagination {
  min-width: 170px;
  justify-content: space-between;
}
.paginate_button a {
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--ifl--primary) !important;
  border-radius: 5px;
  box-shadow: none !important;
  border: 0;
  margin-left: 5px !important;
}
.paginate_button a:hover {
  background-color: #eec717 !important;
}
.paginate_button.disabled {
  opacity: 0.23;
}
.dataTables_info {
  padding: 0 !important;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 500;
  width: 90px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
}
.action__dropdown {
  display: inline-block;
  width: 210px;
}
.action__dropdown button {
  background-color: var(--ifl--secondary);
  color: var(--ifl--white);
  text-transform: uppercase;
  font-size: 18px;
  letter-spacing: 0.8px;
  font-weight: 600;
  padding: 14px 20px;
  text-align: start;
  border-radius: 50px;
  border: 0px;
  outline: none;
  box-shadow: none;
  line-height: normal;
}
.action__dropdown .dropdown-toggle::after {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0;
}
.action__dropdown .dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 4px 77px rgba(0, 0, 0, 0.051);
  border-radius: 16px;
  padding: 0;
  overflow: hidden;
  color: #556575;
  font-weight: 500;
  font-size: 0.875rem;
  width: 100%;
  border: 0;
  z-index: 2;
}
.resize--none {
  resize: none;
}
.bg--dateIcon {
  background-image: url("../images/calendar.png");
  background-repeat: no-repeat;
  background-position: center right 15px;
}
.action__dropdown .dropdown-menu .dropdown-item {
  padding: 0.75rem 1rem;
}
.action__dropdown .dropdown-menu .dropdown-item:hover,
.action__dropdown .dropdown-menu .dropdown-item:focus,
.action__dropdown .dropdown-menu .dropdown-item:active {
  background-color: #e7e7e7;
  color: #556575;
  font-weight: 600;
}
.dropdown-toggle.show::after {
  border-bottom: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-top: 0;
  border-left: 0.3em solid transparent;
}
.modal-backdrop.show {
  background-color: var(--ifl--accent);
  opacity: 0.9;
}
.importmodal .modal-dialog {
  max-width: 1000px;
}
.importmodal.importFormModal .modal-dialog {
  max-width: 690px;
}
.importmodal .modal-header {
  padding: 1.25rem;
  font-weight: 600;
  font-size: 1.5rem;
  border-color: rgb(85 101 117 / 10%);
}
.importmodal .modal-body,
.importmodal .modal-footer {
  padding: 1.5rem;
}
.upload__boxwrapper {
  border: 1px dashed rgba(54, 138, 202, 0.8);
  border-radius: 20px;
  min-height: 400px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal-footer {
  padding-top: 0;
  border: 0;
}
.uploadBtn {
  background-color: var(--ifl--accent);
  padding: 0.875rem 1.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.8px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  border: 0;
  box-shadow: none;
  outline: none;
  border-radius: 50px;
  color: var(--ifl--white);
}
.upload__filebox--text {
  color: var(--ifl--text);
  font-size: 12px;
  text-align: center;
  line-height: 1.6;
}
.maxw--260 {
  max-width: 260px;
}
.uploadBtn:hover,
.saveBtn:hover {
  background-color: #172735;
}
.upload__filebox--text a {
  text-decoration: none;
}
.borderBtn {
  border-radius: 50px;
  border: 1px solid var(--ifl--accent);
  font-size: 0.875rem;
  padding: 0.4rem 1.5rem;
  background-color: var(--ifl--white);
  outline: none;
  box-shadow: none;
  color: var(--ifl--accent);
  font-weight: 600;
  margin: 0;
  cursor: pointer;
}
.saveBtn {
  border-radius: 50px;
  border: 1px solid var(--ifl--accent);
  font-size: 0.875rem;
  padding: 0.45rem 1.5rem;
  background-color: var(--ifl--accent);
  outline: none;
  box-shadow: none;
  color: var(--ifl--white);
  font-weight: 600;
  margin: 0;
  cursor: pointer;
}
button:disabled {
  color: var(--ifl--white);
  background-color: #26364380;
  border-color: #929aa1;
  pointer-events: none;
}
.progressbar__outer--count {
  color: var(--ifl--accent);
  font-weight: 600;
  font-size: 0.875rem;
  margin: 0;
}
.progressbar__outer {
  width: 100%;
  max-width: 460px;
}
.custombar {
  background-color: #d8edfe;
}
.progress-bar {
  background-color: #368aca;
}
.lt--08 {
  letter-spacing: 0.08px;
}
.tostalert {
  box-shadow: 0px 4px 77px rgba(0, 0, 0, 0.1);
  border-radius: 8px 0px 0px 8px;
  padding: 1.5rem 1.25rem;
  border-left: 8px solid #008000;
  position: fixed;
  bottom: 40px;
  right: 0;
  min-width: 250px;
  z-index: 3;
  background-color: var(--ifl--white);
  max-width: 250px;
}
.tostalert--heading {
  font-size: 1rem;
  color: var(--ifl--whatsapp);
  font-weight: 600;
  margin: 0;
  line-height: 1.2;
}
.tostalert--text {
  font-size: 0.65rem;
  color: var(--ifl--accent);
  line-height: 1.2;
  margin: 0;
  font-weight: 500;
}
.text--danger {
  color: #f16667;
}
.font--14 {
  font-size: 0.875rem !important;
}
.importFormModal .modal-title {
  font-size: 1.5rem !important;
  font-weight: 700;
  color: #00354e;
}
.close--button {
  background: transparent;
  padding: 0;
  border: 0;
  outline: none;
}
.importmodal .modal-content {
  border: 0;
  border-radius: 16px;
}
.importmodal.importFormModal .modal-header {
  border: 0;
  padding-bottom: 0;
}
/* .importmodal .modal-footer {
    padding-left: .8rem;
    padding-right: .8rem;
} */
form.modalForm {
  min-height: 630px;
  display: flex;
  flex-direction: column;
}
.form__input--box {
  background-color: #fafafa;
  border: 1px solid #94a1b1;
  padding: 0.765rem 1rem;
  color: #4f4f4f;
  font-size: 0.875rem;
  font-weight: 500;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
}
.form__input--box::placeholder {
  color: #4f4f4fb4;
}
.importFormModal .nice-select.custom--select {
  background-color: #fafafa;
  width: 100%;
  padding-top: 0.09rem;
  padding-bottom: 0.09rem;
}
.font--18 {
  font-size: 1.125rem !important;
}
.saveBtn--lg {
  background-color: var(--ifl--secondary);
  font-size: 1.125rem;
  min-width: 270px;
  padding: 0.65rem 1.5rem;
}
.importmodal.alertmodal .modal-dialog {
  max-width: 360px;
}
.alert-m-heading {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
  color: #1e2a36;
}
.max--320 {
  max-width: 320px;
}
.alert-m-text {
  color: var(--ifl--secondary);
  font-weight: 500;
  line-height: 1.6;
  font-size: 1rem;
  margin: 0;
}
.pink--fill {
  background-color: #f16667;
  border-color: #f16667;
  padding: 0.7rem 1.5rem;
  line-height: normal;
}
.pink--fill:hover {
  background-color: #e05858;
  border-color: #e05858;
}
.d-btn-two {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.custom__dataTable table th::after,
.custom__dataTable table th::before {
  display: none !important;
}
.custom__dataTable table th .arrow--set {
  background-image: url("../images/normal.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 15px;
  display: inline-block;
  margin-left: 5px;
  width: 15px;
  vertical-align: middle;
  right: 0;
  position: absolute;
  top: 2px;
}
.custom__dataTable table th.sorting_asc .arrow--set {
  background-image: url("../images/asc.png");
}
.custom__dataTable table th.sorting_desc .arrow--set {
  background-image: url("../images/dsc.png");
}
.user__profile {
  display: inline-block;
}
.user__profile--pic {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}
.user__profile--pic > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.v-align-middle {
  vertical-align: middle;
}
.user__profile .dropdown.action__dropdown {
  width: auto;
}
.user__profile .dropdown.action__dropdown .dropdown-toggle {
  background: transparent;
  padding: 0;
}
.user__profile .dropdown.action__dropdown .dropdown-toggle::after {
  display: none;
}
.user__profile .action__dropdown .dropdown-menu {
  min-width: 210px;
  margin-top: 20px !important;
}
.th--heading {
  position: relative;
  padding-right: 1.25rem;
}
.navbar__custom--logo {
  display: inline-block;
}
.error--field {
  border-color: #f16667;
}
.all--error {
  color: #f16667;
  font-size: 0.875rem;
  font-weight: 500;
}
.parentELStart,
.parentELEnd {
  position: relative;
}
.daterangepicker {
  width: calc(100% + 15px);
  box-shadow: 0px 2px 20px rgb(0 0 0 / 10%);
  border-radius: 5px;
  border: 0;
}
.daterangepicker.single .drp-calendar.left {
  max-width: 100%;
  width: 100%;
}
.daterangepicker .drp-buttons {
  border: 0;
}
.daterangepicker .cancelBtn {
  background: #f3faff !important;
  border: 1px solid rgba(38, 54, 67, 0.3) !important;
  outline: none !important;
  border-radius: 70px;
  font-size: 14px;
  color: #263643;
  height: auto;
  padding: 10px 20px !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  letter-spacing: 0.8px;
  margin-bottom: 8px;
}
.daterangepicker .applyBtn {
  background: #2636437c !important;
  border: 1px solid #959da4 !important;
  outline: none !important;
  border-radius: 70px;
  font-size: 14px;
  color: #fff;
  height: auto;
  padding: 10px 20px !important;
  text-transform: uppercase;
  font-weight: 600 !important;
  letter-spacing: 0.8px;
  margin-left: 1rem !important;
  margin-bottom: 8px;
}
.daterangepicker .calendar-table td {
  font-weight: 400;
  color: #1e2a36;
  height: 36px;
  border-radius: 50%;
  padding: 0;
  line-height: 40px;
  font-family: "Montserrat", sans-serif;
}
.daterangepicker .calendar-table td.off {
  color: #959da4;
}
.daterangepicker .calendar-table td.active {
  background-color: #c0c0c0;
  height: 36px;
  border-radius: 50%;
  padding: 0;
  line-height: 40px;
  font-weight: 500;
}
.daterangepicker .calendar-table td.off.active {
  color: #1e2a36;
}
.bg--timeIcon {
  background-image: url("../images/darkarrow.png");
  background-position: center right 15px;
  background-repeat: no-repeat;
}
.bootstrap-datetimepicker-widget table {
  background: #fafafa;
  width: 172px;
}
.timepicker-minutes table td,
.timepicker-hours table td {
  padding: 5px;
}
.bootstrap-datetimepicker-widget button[data-action] {
  margin-right: 5px;
  margin-left: 5px;
}
.bootstrap-datetimepicker-widget a[data-action] {
  padding: 0;
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}
.bootstrap-datetimepicker-widget a[data-action]:active {
  outline: none !important;
  box-shadow: none !important;
  border: 0;
}
.bootstrap-datetimepicker-widget table td span {
  margin: 0;
  height: 35px;
  line-height: 35px;
  margin: 0;
  outline: none !important;
  box-shadow: none !important;
}
.bootstrap-datetimepicker-widget table td {
  height: 25px;
  line-height: 25px;
}
.timepicker-hours td.hour {
  padding: 6px;
}
li > .timepicker {
  background: #fafafa;
  width: 180px;
  padding: 0px 0px;
  border: 0.5px solid #e9e9e9;
  border-radius: 6px;
  overflow: hidden;
  margin-top: 2px;
}
.form__input--box + .bootstrap-datetimepicker-widget {
  border: 0;
  background-color: transparent;
  width: max-content;
}
.form__input--box + .bootstrap-datetimepicker-widget::after,
.form__input--box + .bootstrap-datetimepicker-widget::before {
  display: none;
}
.bootstrap-datetimepicker-widget table td span:hover {
  background: transparent;
}
/* a[title="Decrement Hour"] span, a[title="Increment Hour"] span, a[title="Decrement Minute"] span, a[title="Increment Minute"] span {
    background: #f1f1f1;
} */
.timepicker table.table-condensed tr td {
  border-right: 0.5px solid #e9e9e9;
}
.timepicker table td.separator {
  padding: 0;
  border-right: 0px !important;
  display: none;
}
.timepicker table.table-condensed tr td:last-child {
  border-right: 0 !important;
}
.timepicker table.table-condensed tr td:nth-child(1n + 3) {
  background-color: var(--ifl--white);
}

.timepicker button[data-action="togglePeriod"],
.timepicker button[data-action="togglePeriod"]:active,
.timepicker button[data-action="togglePeriod"]:focus {
  background-color: var(--ifl--accent);
  border-color: var(--ifl--accent);
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
}
.bootstrap-datetimepicker-widget .timepicker-hour,
.bootstrap-datetimepicker-widget .timepicker-minute {
  font-size: 16px;
  font-weight: 500;
}
.bootstrap-datetimepicker-widget .btn[data-action="incrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="incrementMinutes"]::after {
  content: "";
  border-bottom: 3px solid #999;
  border-right: 3px solid #999;
  clip: auto;
  overflow: visible;
  width: 11px;
  height: 11px;
  transform: rotate(225deg);
  margin-left: -31px;
  margin-top: 18px;
}
.bootstrap-datetimepicker-widget .btn[data-action="decrementHours"]::after,
.bootstrap-datetimepicker-widget .btn[data-action="decrementMinutes"]::after {
  content: "";
  border-bottom: 3px solid #999;
  border-right: 3px solid #999;
  clip: auto;
  overflow: visible;
  width: 11px;
  height: 11px;
  transform: rotate(45deg);
  margin-left: -32px;
  margin-top: 15px;
}
.item--link--active {
  display: none;
}
.active .item--link {
  display: none;
}
.active .item--link--active {
  display: block;
}
.custom__calendar__full {
  background: #ffffff;
  border: 1px solid #e7e8f1;
  box-shadow: 0px 0px 40px rgb(0 0 0 / 7%);
  border-radius: 20px;
  padding: 2rem 1.25rem 1.25rem;
  position: relative;
}
.fc-header-toolbar .fc-toolbar-chunk:first-child > h2 {
  font-size: 0;
  position: relative;
}
.fc-header-toolbar .fc-toolbar-chunk:first-child > h2::before {
  content: "My Schedule";
  font-size: 20px;
  font-weight: 700;
  color: #324452;
}
th.fc-col-header-cell {
  padding: 25px 10px !important;
}
td.fc-timegrid-slot {
  padding: 15px 0px !important;
}
.fc-header-toolbar .fc-toolbar-chunk:last-child > div {
  background: #efefef;
  padding: 5px;
  display: flex;
  align-items: center;
  border-radius: 50px;
}
.fc-header-toolbar .fc-toolbar-chunk:last-child > div .fc-button {
  border-radius: 50%;
  width: 32px;
  height: 32px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.fc-header-toolbar .fc-toolbar-chunk:last-child > div .fc-button .fc-icon {
  font-size: 18px;
}
.fc-header-toolbar .fc-toolbar-chunk:last-child > div .fc-toolbar-title {
  margin-left: 15px;
  margin-right: 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
}
.fc-direction-ltr .fc-timegrid-slot-label-frame {
  text-align: center !important;
}
.fc-direction-ltr .fc-timegrid-slot-label-frame > * {
  font-size: 12px;
  color: var(--ifl--secondary);
  font-weight: 500;
}
.fc-timegrid-axis-cushion {
  font-size: 14px;
  font-weight: 600;
  color: var(--ifl--secondary);
  text-transform: capitalize;
  max-width: 80px !important;
  display: none;
  position: relative;
}
.fc-daygrid-body .fc-timegrid-axis-frame {
  justify-content: center !important;
}
.fc-daygrid-body .fc-timegrid-axis-frame::before {
  content: "Time";
  font-size: 14px;
  font-weight: 600;
  color: var(--ifl--secondary);
  text-transform: capitalize;
}
.fc-theme-standard td,
.fc-theme-standard th {
  border-color: #32445233;
}
.custom__calendar__full .fc-h-event {
  background-color: rgba(50, 68, 82, 0.16) !important;
  border: 1px solid rgba(50, 68, 82, 0.16) !important;
  border-radius: 4px;
  box-shadow: none !important;
  outline: none !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
}
.custom__calendar__full .fc-h-event .fc-event-main {
  color: var(--ifl--secondary);
  font-size: 12px;
  font-weight: 500;
}
.custom__calendar__full .fc-v-event {
  background-color: var(--ifl--secondary) !important;
  border-color: var(--ifl--secondary) !important;
  padding: 6px;
}
.custom__calendar__full .fc .fc-col-header-cell-cushion {
  font-weight: 600;
  font-size: 14px;
  color: #324452;
  text-decoration: none;
}
.tg--icon {
  margin: 0 0.5rem;
  padding: 0;
  position: relative;
  border: none;
  height: 1.5rem;
  width: 3.45rem;
  border-radius: 1.5rem;
  transition: background-color 0.25s;
  background-color: #ffffff;
  border: 1px solid #bad4f6;
  vertical-align: middle;
}
.tg--icon > .handle {
  width: 1.125rem;
  height: 1.125rem;
  border-radius: 1.125rem;
  background: #a9c7f0;
  position: absolute;
  top: 0.1rem;
  transition: 0.2s ease all;
  left: 2px;
}
.tg--icon.active > .handle {
  left: 2rem;
  transition: left 0.25s;
}
.tg--icon::before,
.tg--icon::after {
  line-height: 1.5rem;
  width: 0.5rem;
  text-align: center;
  font-weight: 600;
  font-size: 0.55rem;
  position: absolute;
  bottom: 0;
  top: 0;
  transition: opacity 0.25s;
}
.tg--icon::after {
  content: "Yes";
  opacity: 0;
  padding-left: 15px;
}
.tg--icon::before {
  content: "No";
  opacity: 1;
  padding-left: 15px;
}
.tg--icon::before,
.tg--icon::after {
  line-height: 1.4rem;
  color: #000000;
  left: 0.4125rem;
  width: 2.325rem;
  font-size: 12px;
  font-weight: 500;
}
.tg--icon.active::after {
  opacity: 1;
  text-align: left;
  padding-left: 0;
}
.tg--icon.active::before {
  opacity: 0;
  text-align: right;
}
.tg--icon:not(.active) > .handle {
  background-color: #f0aeae;
  border: 1px solid #f0aeae;
}
.tg--icon:not(.active) {
  border-color: #fbd0d0;
}
.add__event .tg--icon:not(.active) {
  border-color: #c13c3c;
}
.add__event .tg--icon:not(.active) > .handle {
  background-color: #c13c3c;
  border: 1px solid #c13c3c;
}
.add__event .tg--icon.active > .handle {
  background: #2f80ed;
}
.add__event .tg--icon.active {
  border-color: #2f80ed;
}
.tg--icon.active > .handle {
  background: #a9c7f0;
}
.edit__access {
  text-align: right;
}
.edit__access {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1rem;
  font-weight: 500;
}
.form__select--result {
  font-size: 1rem;
  color: var(--ifl--accent);
  font-weight: 600;
  margin: 0;
}
.font--20 {
  font-size: 1.25rem !important;
}
.font--16 {
  font-size: 1rem !important;
}
.font--600 {
  font-weight: 600 !important;
}
.min-w-120 {
  min-width: 120px !important;
}
.backbtn__box {
  position: fixed;
  left: 20px;
  top: 110px;
  z-index: 1;
  flex-shrink: 0;
}
.backbtn__bordered {
  border: 1px solid #263643;
  filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.102));
  border-radius: 55px;
  padding: 0.5rem 1rem;
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 600;
  color: #263643;
  font-size: 0.875rem;
}
.backbtn__bordered:hover {
  background-color: var(--ifl--accent);
  color: var(--ifl--white);
}
.backbtn__bordered:hover svg path {
  fill: var(--ifl--white);
}
.add__event {
  background-color: var(--ifl--white);
  border-radius: 16px;
  padding: 2rem 2.8rem;
  max-width: 720px;
  margin-left: auto;
  margin-right: auto;
}
.add__event .modal-title {
  color: #00354e;
  font-weight: 700;
  font-size: 24px;
  margin: 0;
}
.font--700 {
  font-weight: 700 !important;
}
.modalnote--text {
  font-weight: 500;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 30px;
}
.action__dropdown .addevnt--btn {
  padding-right: 43px;
  padding-left: 22px;
}
.nice-select.disabled {
  border-color: rgb(148 161 177 / 60%) !important;
  background: rgb(250 250 250 / 60%) !important;
  color: #cdcdcd;
}
.fc-timegrid-slots tr:nth-child(even) td.fc-timegrid-slot-minor:nth-child(1) {
  border-top-style: none !important;
}
.fc .fc-timegrid-slot-label {
  vertical-align: bottom !important;
  padding-bottom: 0 !important;
}
.fc .fc-timegrid-slot-label > div {
  position: relative;
  top: 12px;
}
.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) h2 {
  font-size: 18px;
  font-weight: 600;
  width: 48px;
  white-space: nowrap;
  overflow: hidden;
  text-indent: 4px;
  word-spacing: 15px;
  margin: 0;
}
.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) {
  display: inline-flex;
  margin-left: auto;
  margin-right: 5px;
  padding-top: 5px;
}
.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) > div {
  position: relative;
  display: inline-flex;
}
.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) > div .fc-toolbar-title + .fc-toolbar-title {
  direction: rtl;
  width: 55px;
  text-indent: 0;
  padding-right: 5px;
  text-align: right;
  margin-left: -8px;
}
.custom__calendar__full .fc .fc-col-header-cell-cushion:nth-last-word(1) {
  color: red !important;
}
.timepicker-picker tr:first-child td:first-child::before {
  content: "Hrs";
  font-weight: 500;
  font-size: 14px;
  color: #263643;
  width: 100%;
  display: block;
  padding: 0px 0px 0px;
  height: 30px;
  line-height: 40px;
}
.timepicker-picker tr:first-child td:nth-child(3)::before {
  content: "Mins.";
  font-weight: 500;
  font-size: 14px;
  color: #263643;
  width: 100%;
  display: block;
  padding: 0px 0px 0px;
  height: 30px;
  line-height: 40px;
}
.minw--140 {
  min-width: 140px !important;
}
.invite__studentbox {
  background: #ffffff;
  border: 1px solid #e7e8f1;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.051);
  border-radius: 8px;
  position: relative;
  padding: 1.5rem;
  max-width: 470px;
  margin-left: auto;
}
.invite__studentbox--heading {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  margin: 0;
}
.invite__studentbox--text {
  color: rgba(0, 0, 0, 0.5);
  font-weight: 400;
  font-size: 12px;
  margin: 0;
}
.invite__studentbox--input {
  background: #ffffff;
  border: 1px solid #d3d3d3;
  border-radius: 12px;
  padding: 10px;
  font-size: 13px;
  font-weight: 700;
  width: 165px;
  outline: none;
  box-shadow: none;
}
.invite__studentbox--coypybtn {
  background: #ffffff;
  border: 2px solid #324452;
  border-radius: 50px;
  padding: 10px 24px;
  font-size: 14px;
  font-weight: 600;
  outline: none;
  box-shadow: none;
}
.grid__btnitem {
  margin-top: 0.8rem;
}
.whatsappBtn {
  background: #62c86e;
  box-shadow: 8px 6px 12px rgba(4, 150, 225, 0.16);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  vertical-align: middle;
}
.noun-referral {
  position: absolute;
  right: 10px;
  top: 15px;
}
.inviteModal form.modalForm {
  min-height: auto;
}
.importmodal.importFormModal.inviteModal .modal-header {
  padding: 1.8rem;
  padding-bottom: 0;
}
.importmodal.importFormModal.inviteModal .modal-body {
  padding: 1.8rem;
}
.inviteModal .form__input--box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  background-color: #fff;
}
.invite__optionbox {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2.15rem;
}
.share__via--text {
  display: inline-block;
  vertical-align: middle;
  font-weight: 600;
  font-size: 16px;
  color: var(--ifl--dark);
}
.status-inactive {
  color: #f16667;
}
.csv__button {
  display: inline-block;
  border: 1px solid #324452;
  border-radius: 50px;
  padding: 14px 30px;
  line-height: 1;
  outline: none;
  box-shadow: none;
  text-decoration: none;
  background: transparent;
  font-weight: 600;
  font-size: 18px;
}
.searchbar--box.grid1fr {
  grid-template-columns: 18px 1fr;
}
.mapModal.importmodal.importFormModal .modal-dialog .modalForm {
  min-height: auto;
}
.login__wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.login--bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 45vh;
  overflow: hidden;
  max-width: 100%;
}
.footer--bg {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  height: 40vh;
  max-width: 100%;
}
.login--bg img,
.footer--bg img {
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
}
.login__wrapperinner {
  width: 100%;
  max-width: 860px;
  min-height: 85vh;
  display: grid;
  grid-template-columns: 330px 1fr;
  border-radius: 16px;
  background-color: #fff;
  overflow: hidden;
  box-shadow: -4px 0px 4px rgba(0, 0, 0, 0.1), 4px 0px 4px rgba(0, 0, 0, 0.1), 0px 4px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 1;
}
.login__wrapperinner--left {
  background: #183b57;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
}
.login__wrapperinner--title {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  color: #f2f2f2;
  margin: 0;
}
.login__wrapperinner--text {
  font-weight: 700;
  font-size: 32px;
  color: #324452;
  line-height: 1;
  text-transform: uppercase;
}
.login__wrapperinner--right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
}
.showhide--pic {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 12px;
  min-width: 40px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.login__wrapperinner--right .form__input--box {
  background-color: #ffffff;
  border: 1px solid #94a1b1;
}
.mt-ng-60 {
  margin-top: -50px;
}
.mt-ng-120 {
  margin-top: -200px;
}
/* .fc-header-toolbar .fc-toolbar-chunk:nth-child(2) h2 + button {
    display: none;
}
.fc-header-toolbar .fc-toolbar-chunk:nth-child(2) > h2 {
    text-indent: -220px;
    width: 52px;
} */

/* .layer--time {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
} */

@media screen and (min-width: 1400px) {
}

/*ONBOADRDING*/
.onboarding--name {
  font-size: 2rem;
  font-weight: 400;
  color: #424242;
  margin: 0;
}
.onboarding--name strong {
  font-weight: 700;
}
.onboarding--text {
  font-size: 1.5rem;
  font-weight: 500;
  color: #424242;
  margin: 0;
  line-height: 1.4;
}
.startBtn {
  background: #324452;
  border-radius: 50px;
  color: #fff;
  font-weight: 700;
  padding: 0.95rem 2rem;
  line-height: 1;
  font-weight: 600;
  font-size: 1.35rem;
  text-transform: uppercase;
  display: inline-block;
  text-decoration: none;
  text-align: center;
}
.startBtn:hover {
  color: #fff;
  background-color: #00354e;
}
.maxw--500 {
  max-width: 500px;
}
.nextbtn__bordered {
  background: #324452;
  color: var(--ifl--white);
  font-weight: 600;
  font-size: 18px;
  letter-spacing: 0.08em;
  padding: 0.5rem 1rem 0.5rem 1.25rem;
  display: inline-block;
  text-decoration: none;
  border-radius: 50px;
  border: 0;
}
.nextbtn__bordered:hover {
  color: #fff;
  background: #263643;
}
.nextbtn__box {
  position: fixed;
  right: 35px;
  top: 110px;
}
.stepper__box {
  display: flex;
  justify-content: space-between;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
  list-style-type: none;
  padding-left: 0;
  position: relative;
}
.stepper-step {
  flex: auto;
}
.stepper-head {
  display: flex;
  align-items: center;
}
.stepper-head-icon {
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #324452;
  font-weight: 700;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  position: relative;
}
.stepper-head-icon::before {
  display: inline-block;
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #324452;
  margin-bottom: 5px;
  background: var(--ifl--grey);
}
.stepper-step:last-child > .stepper-head {
  justify-content: flex-end;
}
.stepper__box::before {
  content: "";
  position: absolute;
  top: 28%;
  transform: translateY(-50%);
  left: 15px;
  right: 15px;
  background: #324452;
  height: 2px;
}
.stepper-step.current .stepper-head-icon::after {
  display: inline-block;
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 5px solid #324452;
  margin-bottom: 5px;
  background: #fff;
  top: 1px;
  position: absolute;
}
.fileupload--label {
  font-size: 10px;
  font-weight: 600;
  margin: 0;
  color: #00354e;
  text-transform: uppercase;
  cursor: pointer;
}
.photo__pic {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
  background: #f0f0f0;
}
.photo__pic > img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}
.stepper-step.completed .stepper-head-icon::after {
  content: "";
  background-color: #324452;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  width: 22px;
  height: 22px;
  display: block;
  top: 1px;
  position: absolute;
  border-radius: 50%;
}
.bs-stepper-header .step.completed .bs-stepper-circle::after {
  content: "";
  background-color: #324452;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20px;
  width: 22px;
  height: 22px;
  display: block;
  top: -1px;
  left: -1px;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #324452;
}
.fade--text {
  color: rgba(66, 66, 66, 0.53) !important;
}

.font--0 *,
.font--0 ::before,
.font--0 ::after {
  font-size: 0 !important;
}
.min-h-auto {
  min-height: auto !important;
}
.boarding--modal .modal-dialog {
  max-width: 410px;
}
.boarding--modal .startBtn {
  border: 0;
  font-size: 18px;
  padding: 1rem;
}
.boarding--modal .modal-title {
  font-size: 20px;
}
.boarding--modal .close--button {
  position: absolute;
  right: 20px;
}
.boarding--modal .modal-header {
  border: 0;
  padding-bottom: 0;
}

/*stepper css starts*/
.bs-stepper-header .step-trigger {
  flex-direction: column;
  padding: 0;
}
.bs-stepper-header .step-trigger:hover {
  background: transparent;
}
.bs-stepper-header .bs-stepper-label {
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: #324452;
  font-weight: 700;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  position: relative;
  margin: 0;
}
/* .bs-stepper-header.stepper__box::before {
    display: none;
} */
.bs-stepper-header .bs-stepper-circle {
  display: inline-block;
  content: "";
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #324452;
  position: relative;
  margin: 0;
  margin-bottom: 5px;
  background: var(--ifl--grey);
}
.bs-stepper-header .step.active .bs-stepper-circle::before {
  display: inline-block;
  content: "";
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 5px solid #324452;
  margin-bottom: 5px;
  background: #fff;
  top: -1px;
  position: absolute;
  left: -1px;
  z-index: 1;
}
.bs-stepper .line,
.bs-stepper-line {
  display: none;
}
.secondbox-show {
  display: none;
}
.showHide .secondbox-show {
  display: block;
}
.showHide .firstbox-show {
  display: none;
}
.bs-stepper-content .nextbtn__box,
.bs-stepper-content .backbtn__box {
  position: fixed;
  top: 110px;
}
/*stepper css ends*/

.page-not-found-wrapper {
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title1 {
    font-size: 20rem;
  }

  .title2 {
    font-size: 5rem;
  }
}

/* DROPDOWN_SELECT*/
.dpdSelect {
  background-color: #fafafa !important;
  border: 1px solid #94a1b1 !important;
  border-radius: 8px !important;

  padding: 12px !important;
  color: #4f4f4f;
  font-size: 0.875rem;
  font-weight: 500;
  outline: none;
  box-shadow: none;
}
.dpdSelect .react-dropdown-select-dropdown {
  border-radius: 10px;
}
.dpdSelect .option {
  color: #556575;
  height: auto;
  padding: 0 12px;
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  max-height: 40px;
  outline: 0;

  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}
.dpdSelect .option:hover {
  background-color: #f6f6f6;
}
.dpdSelect .option.selected {
  font-weight: 700;
}

.dpdSelect .react-dropdown-select-dropdown-handle {
  width: 16px;
  height: 16px;
}
.dpdSelect .dropdownIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: rotate(180deg);
}
.dpdSelect .dropdownIcon.open {
  transform: rotate(0deg);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/**
 * ==============================================
 * Dot Flashing
 * ==============================================
 */
.dot-flashing {
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 15px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #9880ff;
  color: #9880ff;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #9880ff;
  }
  50%,
  100% {
    background-color: rgba(152, 128, 255, 0.2);
  }
}

.talking-box-enter {
  opacity: 0.01;
}

.talking-box-enter.talking-box-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.talking-box-leave {
  opacity: 1;
}

.talking-box-leave.talking-box-leave-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.dot-elastic {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #f6d670;
  color: #f6d670;
  animation: dot-elastic 1s infinite linear;
  left: 40px;
}
.dot-elastic::before,
.dot-elastic::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-elastic::before {
  left: -25px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #f6d670;
  color: #f6d670;
  animation: dot-elastic-before 1s infinite linear;
}
.dot-elastic::after {
  left: 25px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #f6d670;
  color: #f6d670;
  animation: dot-elastic-after 1s infinite linear;
}

@keyframes dot-elastic-before {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1.5);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 1.5);
  }
  75% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(1, 1);
  }
}
@keyframes dot-elastic-after {
  0% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1, 0.67);
  }
  75% {
    transform: scale(1, 1.5);
  }
  100% {
    transform: scale(1, 1);
  }
}
@media screen and (max-width: 480px) {
  .navbar__custom {
    //     box-sizing: border-box;
    //     position: absolute;
    //     width: 375px;
    height: 62px;
    //     left: 0px;
    //     // top: 77px;
  }
}
