.event-card-scroll-container::-webkit-scrollbar {
  width: 0; /* Hide scrollbar width */
  height: 0; /* Hide scrollbar height */
}
@media (min-width: 992px) {
  .event-card-scroll-container {
    max-height: 60vh;
    overflow-y: scroll;
  }
}

@media (max-width: 991px) {
  .event-card-scroll-container {
    max-height: 70vh;
    overflow-y: scroll;
  }
}

.event_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #f2f2f2;
  box-shadow: 0px 14px 33px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px 12px;
  border: 0px;
  height: auto;
}

.upcoming_event-title {
  color: #000421;
  font-weight: 600;
}
.no_testCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f2f2f2;
  height: 50vh;
  /* padding-right: 12rem; */
}
.view__detailbar-btn {
  background-color: #275aa6;
  color: #275aa6;
  padding: 18px 25px;
  border-radius: 25px;
  height: 46px;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  border: 0;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s;

  background-color: #3a5aa1;
  color: #fff;
}

.view__detailbar-btn:hover {
  background-color: #f2f2f2;
  color: #275aa6;
  border: 1px solid #000421;
}

@media (max-width: 768px) {
  .upcoming_event-title {
    font-size: 16px;
  }

  .view__detailbar-btn {
    font-size: 12px;
  }
}
@media (max-width: 1200px) {
  .event_card {
    padding: 20px;
    width: 90%;
    margin: 0 auto;
  }

  .upcoming_event-title {
    font-size: 1.2rem;
  }

  .no_testCard {
    height: 70vh;
  }
}
@media (min-width: 1200px) {
  .event_card {
    padding: 20px;
    width: 90%;
    margin-right: 20px;
  }

  .upcoming_event-title {
    font-size: 1.2rem;
  }

  .no_testCard {
    height: 70vh;
  }
}

/* .border__navBtn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.9rem 1.9rem;
  line-height: 1;
  background: radial-gradient(160% 160% at 50.4% 58%, rgba(255, 249, 215, 0.213) 0%, rgba(255, 255, 255, 0.111) 100%);
  border-radius: 50px;
  text-decoration: none;
  background: radial-gradient(#31394b, #31394b) padding-box,
    linear-gradient(to right, #b6a238, #eddc82, #a89637) border-box;
  border: 2px solid transparent;
} */

/* .border__navBtn span {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
} */

.admissionId-container {
  display: flex;
  align-items: center;
}
.login-id-container {
  display: flex;
  align-items: center;
  margin-right: 10px; /* Adjust margin as needed */
}
.user-names {
  display: flex;
  align-items: center;
}
.svgId-box {
  display: flex;
  margin-top: -49px;
}

@media screen and (max-width: 480px) {
  .admissionId-container {
    gap: 4px;
    width: 208px;
    margin-left: 100px;
    margin-top: -8px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 100;
  }

  .admissionId-container > .login-id {
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    color: #6996d8;
  }

  .admissionId-container > .svgId-box > .admission-id {
    font-weight: 400;
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    color: #f2f2f2;
  }
  .user-names {
    margin-left: 1.5rem;
  }
}

.login-id {
  color: var(--White, #f2f2f2);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 5px;
  margin-top: 1rem;
}

.vertical-line {
  opacity: 0.7;
  width: 1px;
  height: 44px;
  border-left: 1px solid #f2f2f299;
  margin: 0 1.2rem; /* Adjust margin as needed */
}

.admission-id {
  gap: 12px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  color: #f2f2f2;
  line-height: 40px;
  margin-top: 1rem;
}

.copy-btn {
  width: 64px;
  height: 27px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  padding: 6px 4px 6px 4px;
  gap: 4px;
  background: #ffffff1c;
  color: #fff9d7;
  cursor: pointer;
}
.profile-img {
  margin-right: 0rem;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

/* .copy-text {
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  line-height: 14.63px;
  color: #f2f2f2;
} */

.text-copy {
  margin-top: 16px;
  font-size: 12px;
}

.popup-container {
  display: flex;
  margin-top: 5px;
}

.adm-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.07px;
  color: white;
}

.schl-text {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 17.07px;
  color: white;
  position: relative;
  bottom: 10px;
}

/* .copy-container {
  margin-top: -4px;
  width: 70;
  height: 27px;
  border-radius: 30px;
  padding: 6px 4px 6px 4px;
  background: gray;
  cursor: pointer;
} */

/* .loginId-text {
  opacity: 80%;
  font-family: "Montserrat", sans-serif;
  font-size: 13px;
  line-height: 15.85px;
  color: #f2f2f2;
} */

/* .popup-text {
  font-family: "Montserrat", sans-serif;
  margin-top: -15px;
  margin-left: 19px;
  font-weight: 500;
  font-size: 12px;
  line-height: 14.63px;
  color: #f2f2f2;
} */

.fill__navBtn {
  justify-content: center;
  align-items: center;
  padding: 1.05rem 2.25rem;
  line-height: 1;
  margin-top: 0rem;
  text-decoration: none;
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  border-radius: 50px;
}

/* .modal__navBtn {
  padding: 1.05rem 2.25rem;
  line-height: 1;
  margin-top: 0rem;
  text-decoration: none;
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  border-radius: 50px;
}

.modal__navBtn span {
  width: 57px;
  height: 15px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #3c3306;
  letter-spacing: 0.02em;
} */

/* .span-elements {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.fill__navBtn span {
  color: #3c3306;
  letter-spacing: 0.02em;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}

/* .font0 {
  font-size: 0;
} */

.navbar--modified {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9;
  padding: 0.5rem 0rem;
}

.frontheader__banner {
  padding-top: 6rem;
  position: relative;
  bottom: 80px;
  overflow: hidden;
  min-height: 60vh;
}

.navbar--modified .navbar-brand {
  width: 120px;
  padding: 0;
}

.navbar--modified .navbar-brand > img {
  max-width: 100%;
}

.top-illustartion-one {
  position: absolute;
  top: -45px;
  left: -152px;
}

.top-illustartion-two {
  position: absolute;
  top: 50px;
  right: -110px;
  max-width: 100%;
}

.white__layerbg {
  background: linear-gradient(170deg, rgba(226, 124, 40, 0.817) -5%, rgb(61 43 28 / 0%) 53%);
  position: absolute;
  top: 0px;
  right: 0;
  left: 0;
  min-height: 80vh;
}

.banner__contentbox--title {
  font-weight: 600;
  font-size: 1.25rem;
  margin: 0;
  line-height: 1.3;
  color: #fff;
}
.event_card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #f2f2f2;
  box-shadow: 0px 14px 33px 1px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 20px 12px; /* Increased top and bottom padding */
  border: 0px;
  height: auto; /* You can adjust this as needed */
}

.upcoming_event-title {
  color: #000421;
  font-weight: 600;
}
.no_testCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f2f2f2;
  height: 50vh;
  /* align-self: flex-start; */
}
.view__detailbar-btn {
  background-color: #275aa6;
  color: #275aa6;
  padding: 18px 25px;
  border-radius: 25px;
  height: 46px;
  font-weight: 600;
  font-size: 16px;
  text-decoration: none;
  border: 0;
  box-shadow: none;
  outline: none;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition effect */

  /* Initial background and text color */
  background-color: #3a5aa1;
  color: #fff;
}

.view__detailbar-btn:hover {
  background-color: #f2f2f2; /* Background color on hover */
  color: #275aa6; /* Text color on hover */
  border: 1px solid #000421;
}

.text--secondary--grad {
  background: linear-gradient(58.31deg, #fbb040 19.62%, #f7941d 50.65%, #f15a29 86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 700;
}

.banner__contentbox {
  position: relative;
  z-index: 1;
  padding-top: 1.75rem;
}

.banner__contentbox--text {
  line-height: 1.5;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  margin: 0;
}

.membership__card {
  backdrop-filter: blur(17.5px);
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  max-width: 28rem;
  /* min-height: 530px; */
  flex-shrink: 0;
  z-index: 1;
  padding: 1.25rem 1rem;
  background: radial-gradient(96.91% 96.91% at 96.41% 2.3%, rgba(16, 21, 37, 0.3) 0%, rgba(3, 14, 51, 0.3) 100%);
  backdrop-filter: blur(17.5px);
  border-radius: 15px;
  margin-top: 2rem;
  filter: drop-shadow(0px 36px 26px rgba(0, 0, 0, 0.14));
  border: 1px solid #b49f34;
  height: auto;
}
.membership__card2 {
  padding: 6px 1rem;
  margin-top: 1rem;
  max-width: 417px;
  height: auto;
  margin-left: 4rem;
  background: radial-gradient(96.91% 96.91% at 96.41% 2.3%, rgb(15 63 213) 0%, rgb(3 14 51) 100%);
  margin-top: 0px;
  border-radius: 15px;
  margin-top: 1rem;
  filter: drop-shadow(0px 36px 26px rgba(0, 0, 0, 0.14));
  border: 1px solid #b49f34;
  height: auto;
}

.membership__card--title {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #fbd323;
  margin: 0;
}

.funkylines {
  position: absolute;
  top: 0;
  right: 0;
  mix-blend-mode: multiply;
}

.membership__card .nav-link {
  background: transparent !important;
  border: 0;
  outline: none;
  box-shadow: none;
  font-weight: 400;
  color: #fff;
  padding: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  line-height: normal;
  font-size: 14px;
  min-height: 40px;
}

.membership__card .nav-link.active {
  font-weight: 500;
  color: #3c3306;
  background-color: #e2cc5c !important;
}

.membership__card .nav-tabs {
  margin-top: 1rem;
}

.membership__card .nav-tabs {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  border-bottom-width: 4px;
  border-color: rgba(226, 204, 92, 0.1);
}

.membership__card .nav-link > span {
  line-height: 1;
}

.member--inputbox {
  background: rgba(0, 0, 0, 0.619);
  border: 1px solid #736b42;
  border-radius: 4px !important;
  outline: none;
  line-height: normal;
  padding: 14px 10px;
  color: #fff;
  letter-spacing: 0.02em;
  font-size: 13px;
  font-weight: 400;
  outline: none;
  box-shadow: none;
  width: 100%;
  font-family: "Source Code Pro";
}

#eyeicon {
  opacity: 0.5;
  transition: opacity 0.2s ease; /* Optional: adds a smooth transition effect */
}

/* When the input field is in focus or has content, set eyeicon opacity to 1 */
.member--inputbox:focus + .input-group-append #eyeicon,
.member--inputbox:not(:placeholder-shown) + .input-group-append #eyeicon {
  opacity: 1;
}

/* .member--inputbox:hover {
  border-color: #b6a238;
} */

.input-highlight:valid {
  border-color: #edcd6a;
}

.member--inputbox::placeholder {
  color: rgba(255, 255, 255, 0.7) !important;
}

.mb-12 {
  margin-bottom: 12px;
}

.otp--btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.85rem 0.2rem;
  line-height: 1.3;
  background: radial-gradient(160% 160% at 50.4% 58%, rgba(255, 249, 215, 0.213) 0%, rgba(255, 255, 255, 0.111) 100%);
  border-radius: 0px;
  text-decoration: none;
  background: radial-gradient(#31394b, #31394b) padding-box,
    linear-gradient(to right, #b6a238, #eddc82, #a89637) border-box;
  border: 0px solid transparent;
  position: absolute;
  top: 1px;
  right: 13px;
  bottom: auto;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 1;
  min-width: 115px;
  height: 44px;
}

.otp--btn span {
  background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
}

.error--label {
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  display: block;
}

.error--label sub {
  color: #9b0a0a;
  bottom: 0;
  margin-right: 2px;
  font-size: 11px;
}

.form-check.check-opacity {
  position: relative;
  display: block;
  margin-top: 15px;
  margin-bottom: 10px;
  padding-left: 0;
}

.form-check.check-opacity .form-check-label {
  margin-left: 1.75rem;
  font-size: 12px;
  line-height: 1.6;
  color: rgba(255, 255, 255, 0.7) !important;
  font-weight: 500;
}

.check-opacity input {
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 0;
  margin-top: 0;
  z-index: 1;
  cursor: pointer;
  right: 0;
  opacity: 0;
  bottom: 0;
  width: 16px;
  height: 16px;
}

.check-opacity .form-check-label input[type="checkbox"] + .input-helper::before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 5px;
  border: solid #b49f34;
  border-width: 2px;
  -webkit-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  position: absolute;
  top: 4px;
  left: 0;
}

.check-opacity .form-check-label input[type="checkbox"] + .input-helper::after {
  -webkit-transition: all;
  transition: all;
  transition-duration: 0s;
  -webkit-transition-duration: 250ms;
  transition-duration: 250ms;
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transform: scale(0);
  transform: scale(0);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23B49F34' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  content: "";
  font-size: 0.9375rem;
  font-weight: bold;
  display: inline-block;
  position: absolute;
  top: 4px;
  left: 0px;
  font-size: 12px;
}

/* .check-opacity .form-check-label input[type="checkbox"]:checked + .input-helper::before {
    background: -webkit-gradient(linear, left top, right top, from(#49d0e2), to(#34afbf));
    background: linear-gradient(to right, #d1b31e, #d1b31e);
    border-width: 0;
} */
.check-opacity .form-check-label input[type="checkbox"]:checked + .input-helper::after {
  width: 18px;
  height: 18px;
  opacity: 1;
  line-height: 18px;
  filter: alpha(opacity=100);
  -webkit-transform: scale(1);
  transform: scale(1);
}

.pe--115 {
  padding-right: 125px !important;
}

.login--supporttext {
  color: #f2f2f2;
  font-family: Montserrat;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.gard--textflow {
  background: var(--Bck, #edcd6a);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-text-fill-color: transparent;
  font-weight: 700;
  font-size: 16px;
  text-decoration: none;
  letter-spacing: 0.32px;
  cursor: pointer;
  font-family: "Montserrat";
}

.gard--textflow.text-decoration-underline::after {
  content: "";
  height: 1px;
  display: block;
  width: 100%;
  background: #b9aa57;
}

.otp--btn:disabled {
  opacity: 0.8;
}

.button--membersubmit {
  background: var(--untitled-gold-solid, linear-gradient(110deg, #b49f34 12.58%, #f2e188 49.16%, #776400 104.2%));
  border-radius: 30px;
  letter-spacing: 0.02em;
  color: #3c3306;
  font-size: 16px;
  font-weight: 700;
  font-family: "Montserrat";
  line-height: 1;
  padding: 19px 30px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border: 1px solid var(--gold-line, #f2e188);
  cursor: pointer;
  min-width: 125px;
  height: 50px;
  margin-top: 25px;
  margin-bottom: 20px;
}
.button--membersubmit:hover {
  border-radius: 30px;
  background: #edcd6a;
  box-shadow: 0px 6px 4px 0px rgba(0, 0, 0, 0.15);
}

.button--membersubmit:disabled {
  opacity: 0.6;
  pointer-events: none;
  cursor: initial;
  color: #3c3306;
}

.partyIcon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.layercube__section {
  position: relative;
  padding: 1.5rem 0rem;
}

.layered--gradbg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

.z1 {
  z-index: 1;
}

.layercube__grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1.5rem;
  text-align: center;
  filter: drop-shadow(0px 13px 22px rgba(0, 0, 0, 0.25));
}

.layercube__grid--item {
  padding: 1.6rem;
  background: radial-gradient(160% 160% at 50.4% 58%, rgba(255, 249, 215, 0.213) 0%, rgba(255, 255, 255, 0.111) 100%);
  border: 1px solid #edcd6a;
  border-radius: 20px;
  display: grid;
  grid-gap: 1rem;
  box-shadow: 0px 13px 22px rgb(0 0 0 / 25%);
  min-height: 165px;
}

.layercube__grid--item img {
  max-width: 40px;
}

.layercube__grid--text {
  font-weight: 500;
  font-size: 16px;
  color: #edcd6a;
  line-height: 1.3;
}

.layercube__grid--text strong {
  font-weight: 600;
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.exp__exellence {
  padding: 1.5rem 0rem;
  margin-top: 1rem;
}

.exp__exellence--heading {
  background: linear-gradient(58.31deg, #fbb040 19.62%, #f7941d 50.65%, #f15a29 86%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 24px;
  line-height: 1.3;
  letter-spacing: 0.02em;
  margin: 0;
}

.exp__exellence--text {
  font-weight: 400;
  font-size: 0.875rem;
  color: #f2f2f2;
  margin: 0;
  line-height: 1.5;
}

.maxw--330 {
  max-width: 330px;
}

.video__bg {
  position: relative;
}

.img--dotted--left {
  position: absolute;
  top: 0;
  left: 0;
}

.img--dotted--right {
  position: absolute;
  bottom: 0;
  right: 0;
}

.video-js {
  width: 100%;
  background-color: transparent;
  border-radius: 15px;
  box-shadow: 0px 24px 9px rgba(0, 0, 0, 0.19);
  overflow: hidden;
}

.vjs-poster img,
.video-js .vjs-tech {
  object-fit: cover;
}

.video-js .vjs-big-play-button {
  box-shadow: 0px 32px 64px rgb(17 17 17 / 8%);
  border-radius: 50%;
  width: 36px;
  height: 36px;
  line-height: 36px;
  border: 0;
  background: rgba(3, 11, 32, 0.6);
  margin: 0;
  transform: translate(-50%, -50%);
}

.vjs-icon-play:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before,
.video-js .vjs-big-play-button .vjs-icon-placeholder:before {
  color: #f2e188;
}

.my-video-dimensions {
  height: 180px !important;
}

.membership__benefits--grid {
  display: grid;
  grid-gap: 1.35rem;
}

.membership__benefits--students .grad12 {
  background: linear-gradient(109.29deg, rgba(0, 123, 176, 0.62) 51.76%, #b8e019 132.5%);
  /* background: linear-gradient(58.31deg, #B6A238 100%, #EDDC82 100%, #A89637 100%); */
  border-radius: 20px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.membership__benefits--teachers .grad12 {
  border-radius: 20px;
  border-bottom: 4px solid var(--Linear, #b6a238);
  background: linear-gradient(110deg, #1b82ae 35.19%, #262362 112.13%);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.membership__benefits--parents .grad12 {
  background: linear-gradient(110.09deg, #1b82ae 35.19%, #262362 112.13%);
  border-radius: 20px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
}

.membership__benefits--item {
  padding: 2rem 1rem;
  position: relative;
  z-index: 1;
  background: linear-gradient(90deg, #b6a238 100%, #eddc82 100%, #a89637 100%);
  border-radius: 20px;
  border-bottom-left-radius: 35px;
  border-bottom-right-radius: 35px;
  overflow: hidden;
}

.membership__benefits--shapes {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.membership__benefits--title {
  color: #edcd6a;
  margin: 0;
  font-weight: 600;
  font-size: 1.5rem;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.membership__benefits--grid {
  margin-top: 1rem;
  position: relative;
}

.ol--list {
  padding-left: 1rem;
  margin: 0;
  padding-right: 0;
  margin-top: 15px;
}

.ol--list li {
  font-weight: 500;
  font-size: 12px;
  color: #fff;
}

.ol--list li + li {
  margin-top: 0.75rem;
}

.membership__benefits--gridbox {
  display: grid;
  grid-template-columns: 55% 45%;
  align-items: center;
  grid-gap: 0.25rem;
}

.party-membox {
  position: absolute;
  top: 80%;
  left: -15px;
}

.grad11 {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 8px;
  border-radius: 20px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
}

.grad12 {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 8px;
  border-radius: 20px;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  overflow: hidden;
}

/* .membership__benefits--item::before {
    content: '';
    position: absolute;
    z-index: -2;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: -15px;
    border-radius: 20px;
    background: linear-gradient(58.31deg, #B6A238 100%, #EDDC82 100%, #A89637 100%);
} */
/* .membership__benefits--item::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: -15px;
    border-radius: 20px;
    background: linear-gradient(60.07deg, #01206D 15.98%, #020A1F 79.88%), #FFFFFF;
} */
.faq--icon {
  position: absolute;
  top: 0px;
  right: -65px;
}

.faq__section {
  position: relative;
  padding: 1.5rem 0rem;
  background: rgba(0, 0, 0, 0.2);
}

.custom__container__faq {
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
}

.custom__container__faq .accordion {
  border: 0;
  background: transparent;
}

.custom__container__faq .accordion .accordion-item {
  background: transparent !important;
  border: 0;
  border-radius: 0 !important;
}

.custom__container__faq .accordion .accordion-item .accordion-header .accordion-button {
  background: transparent;
  border-radius: 0;
  color: #f2f2f2;
  font-weight: 400;
  padding-left: 0;
  box-shadow: none;
  border-bottom: 1px solid rgb(237 220 130 / 51%);
  padding-right: 0.5rem;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.custom__container__faq .accordion .accordion-item .accordion-body {
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  padding-left: 0;
  line-height: 1.6;
}

.custom__container__faq .accordion .accordion-item .accordion-header .accordion-button::after {
  transform: rotate(-90deg);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.custom__container__faq .accordion .accordion-item .accordion-header .accordion-button:not(.collapsed)::after {
  transform: rotate(-180deg);
}

.showhide--pic {
  position: absolute;
  top: 0;
  bottom: auto;
  right: 12px;
  min-width: 50px;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.showhide--pic2 {
  bottom: 15px;
  position: absolute;
  bottom: auto;
  right: 6px;
  min-width: 50px;
  height: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.member--inputbox + .nice-select:after {
  width: 10px;
  height: 10px;
  right: 20px;
  border-width: 1.5px;
  border-color: #fff;
  margin-top: -6px;
}

.member--inputbox + .nice-select:hover {
  border-color: #b6a238;
}

.member--inputbox + .nice-select .list {
  background: #0d155c;
}

.member--inputbox + .nice-select .option {
  color: rgba(255, 255, 255, 0.7);
}

.member--inputbox + .nice-select .option.focus,
.member--inputbox + .nice-select .option.selected.focus,
.member--inputbox + .nice-select .option:hover {
  background-color: #1e4095;
  color: #fff;
}

.error--strike {
  border-color: #e16767;
}

.error--strike--text {
  font-size: 12px;
  font-weight: 500;
  color: #e16767;
  margin-top: 3px;
}

.form__input--box ~ .error--strike--text {
  display: block;
}

@media screen and (min-width: 576px) {
  .blue-illustration {
    max-width: 370px;
  }

  .button--membersubmit {
    width: auto;
  }
}

@media screen and (max-width: 480px) {
  .frontheader__banner {
    padding-top: 20px;
    position: relative;
    bottom: 0px;
    overflow: hidden;
    min-height: 30vh;
  }
  .membership__card {
    margin-top: 1.5rem;
    height: auto;
  }
  .exp__exellence--heading {
    font-size: 1.2rem;
  }
  .exp__exellence--text {
    font-size: 12px;
  }
  .layercube__grid--item {
    border: 0px !important;
  }
  .no_testCard {
    padding-right: 0rem !important;
  }
}

@media screen and (min-width: 768px) {
  .membership__card {
    margin-top: 0;
    height: auto;
  }

  .blue-illustration {
    max-width: 100%;
  }

  .exp__exellence--heading {
    font-size: 1.5rem;
  }

  .video__bg {
    padding: 1.5rem;
  }

  .video__content--desc {
    padding-top: 1rem;
  }

  .exp__exellence {
    padding: 3.5rem 0rem;
  }

  .layercube__section {
    padding: 2.5rem 0rem;
    margin-top: 4rem;
  }

  .ms-md-start {
    margin-left: 0 !important;
  }

  .party-membox {
    display: none;
  }

  .membership__benefits--gridbox {
    grid-template-columns: 65% 35%;
  }

  .membership__benefits--gridbox {
    align-items: flex-start;
  }

  .membership__benefits--title {
    font-size: 2rem;
  }

  .ol--list li {
    font-size: 1.25rem;
  }

  .ol--list {
    padding-left: 1.5rem;
    margin-top: 1.25rem;
  }

  .membership__benefits--item {
    padding: 2rem 0rem 2rem 2.5rem;
  }

  .membership__benefits--grid {
    grid-gap: 2rem;
    margin-top: 2rem;
  }

  .membership__benefits--shapes svg {
    height: 100%;
  }

  .faq__section {
    padding: 3rem 0rem;
  }

  .faq--icon {
    right: 50px;
  }

  .custom__container__faq {
    margin-top: 2rem;
  }
  .frontheader__banner {
    padding-top: 20px;
    position: relative;
    bottom: 0px;
    overflow: hidden;
    min-height: 50vh;
  }
}

@media screen and (min-width: 992px) {
  .membership__card {
    margin-top: 1rem;
    height: auto;
  }
  .navbar--modified {
    padding: 1rem 0rem;
  }

  .navbar--modified .navbar-brand {
    width: auto;
  }

  .top-illustartion-one {
    position: absolute;
    top: 70px;
    left: -25px;
    max-width: 100%;
  }

  .top-illustartion-two {
    position: absolute;
    top: 20px;
    right: auto;
    max-width: 100%;
  }

  .white__layerbg {
    top: 00px;
    min-height: 50vh;
  }

  .banner__contentbox {
    padding-top: 0.7rem;
  }

  .banner__contentbox--title {
    font-size: 1.6rem;
    margin-top: 2rem;
  }

  .banner__contentbox--text {
    font-size: 14px;
  }

  .layercube__grid {
    grid-template-columns: repeat(4, 1fr);
    max-width: 1030px;
    /*margin-left: auto;*/
    /*margin-right: auto;*/
  }

  .layercube__grid--item img {
    max-width: none;
  }

  .layercube__grid--item {
    grid-template-columns: 56px 1fr;
    grid-gap: 1rem;
    align-items: center;
    box-shadow: 0px 51px 23px rgba(0, 0, 0, 0.05);
    min-height: auto;
  }

  .layercube__grid--text {
    font-size: 1.25rem;
  }

  .layercube__section {
    background-color: rgba(255, 255, 255, 0.05);
    padding: 3rem 0rem;
    margin-top: 5rem;
  }

  .layered--gradbg {
    background-image: url("../images/frontside/layered--gradbg.svg");
  }

  .exp__exellence--text {
    font-size: 1.2rem;
  }

  .my-video-dimensions {
    height: 270px !important;
  }

  .video-js .vjs-big-play-button {
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
  .frontheader__banner {
    padding-top: 20px;
    position: relative;
    bottom: 0px;
    overflow: hidden;
    min-height: 55vh;
  }
}

@media screen and (min-width: 1200px) {
  .banner__contentbox--title {
    font-size: 2rem;
  }

  .membership__card--title {
    font-size: 20px;
  }

  .membership__card {
    padding: 2rem 1.5rem;
    margin-top: 1rem;
    max-width: 490px;
    height: auto;
    margin-left: 4rem !important;
  }

  .membership__card .nav-link {
    padding: 10px 20px;
  }

  .membership__card .nav-link.active {
    font-weight: 700;
  }

  .membership__card .nav-tabs {
    margin-top: 1.25rem;
  }

  .layercube__section {
    margin-top: 6rem;
  }

  .video__bg {
    padding: 2rem;
  }

  .video__content--desc {
    padding-top: 2rem;
  }
}

@media screen and (min-width: 1400px) {
  .banner__contentbox--title {
    font-size: 2.25rem;
  }
  .layercube__grid {
    margin-left: 6rem !important;
  }

  .layercube__grid--text {
    font-size: 1.5rem;
  }

  .exp__exellence--heading {
    font-size: 2.5rem;
  }

  .exp__exellence--text {
    font-size: 1.25rem;
  }

  .video__bg {
    padding: 2rem;
  }
}

/* @media screen and (max-width: 480px) {
  .modal__navBtn {
    padding: 6px 10px;
    gap: 10px;
    width: 61px;
    height: 27px;
  }
} */

/* @media screen and (max-width: 480px) {
  .modal__navBtn span {
    width: 57px;
    height: 15px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: #3c3306;
    letter-spacing: 0.02em;
  }
} */

@media screen and (max-width: 480px) {
  .fill__navBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 10px;
    position: absolute;
    width: 61px;
    height: 27px;
    left: 298px;
    top: 15px;
  }
}

@media screen and (max-width: 480px) {
  .fill__navBtn span {
    width: 57px;
    height: 15px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.02em;
  }
}

@media screen and (max-width: 480px) {
  .border__navBtn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 10px;
    gap: 10px;
    position: absolute;
    width: 74px;
    height: 27px;
    left: 214px;
    top: 15px;
  }
}

@media screen and (max-width: 480px) {
  .border__navBtn span {
    width: 54px;
    height: 15px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */
    letter-spacing: 0.02em;
    background: linear-gradient(91.08deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }
}

@media screen and (max-width: 480px) {
  .navbar-brand {
    max-width: 100%;
    position: absolute;
    height: 40px;
    left: 16px;
    top: 9px;
  }
  .frontheader__banner {
    padding-top: 20px;
    position: relative;
    bottom: 0px;
    overflow: hidden;
    min-height: 30vh;
  }

  .navbar-brand > .logo {
    width: 111px;
  }
}
@media (max-width: 768px) {
  .banner__contentbox.no_auth {
    padding-top: 0.75rem !important;
  }
  #signup {
    margin-top: 10px !important;
  }

  .img--dotted--left {
    display: none;
  }
  .img--dotted--right {
    display: none;
  }
}

@media (max-width: 992px) {
  .img--dotted--left {
    width: 130px;
    top: 10px;
    left: 10px;
  }
  .img--dotted--right {
    width: 130px;
    right: 10px;
    bottom: 10px;
  }
}
@media screen and (min-width: 320px) and (max-width: 400px) {
  .login--supporttext {
    font-size: 11px;
  }
}
.examNavbar {
  display: flex;
  width: 100%;
  padding: 10px 20px 10px 30px;
  align-items: center;
  gap: 24px;
  background: var(--White, #fff);
  justify-content: space-between;

  /* Elevation/Soft */
  box-shadow: 0px 4px 77px 0px rgba(0, 0, 0, 0.05);
}
.profile-img {
  width: 48px;
  height: 48px;
  border-radius: 50px;
}
.examNavLogo {
  width: 269px;
  height: 64px;
  cursor: pointer;
}
.banner {
  position: relative;
  width: 940px;
  height: 176px;
}
.banner-img {
  z-index: 1;
}
.banner-heading {
  color: #fff;
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 44px; /* 122.222% */
  position: absolute;
  left: 2rem;
  top: 4rem;
}
.titelCard {
  width: 940px;
  height: 176px;
  border-radius: 1.25rem;
  padding: 1rem 4rem;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  margin-bottom: 2rem;
}
.logo-img {
  position: absolute;
  width: 9rem;
  height: 5rem;
  flex-shrink: 0;
  left: 2rem;
  top: 1rem;
}
.theme-score {
  background: linear-gradient(105deg, #408eda -3.24%, #003262 105.76%);
}
.theme-hots {
  background: #eb8222;
}
.theme-wit {
  background: #6947c6;
}
.theme-math {
  background: #00918e;
}
.theme-english {
  background: #99215b;
}
.card-des {
  color: var(--Grayscale-Off-white, #fcfcfc);
  font-feature-settings: "clig" off, "liga" off;
  position: absolute;
  bottom: 1rem;
  left: 2rem;
  z-index: 2;
  /* Mobile/H6 */
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 133.333% */
}
.illutionCont {
  position: absolute;
  right: 0;
  top: 0.6rem;
  width: 294.335px;
  height: 280.768px;
  transform: rotate(165deg);
  flex-shrink: 0;
  border-radius: 294.335px;
  opacity: 0.2;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.31) 0%, #7db6ed 100%);
  box-shadow: 0 0 10px rgba(252, 252, 252, 0.5);
}
.ellips {
  position: absolute;
}
.ellis-l {
  top: 7rem;
  right: 2rem;
  transform: rotate(200deg);
}
.ellis-r {
  top: 5.8rem;
  right: 10rem;
  transform: rotate(198deg);
  z-index: 2;
}
.arrowLeft {
  display: none;
}
.AttemptCard {
  display: flex;
  flex-direction: column;
  width: 940px;
  height: 210px;
  flex-shrink: 0;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;

  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.doto-illution {
  width: 139.339px;
  height: 6rem;
  flex-shrink: 0;
  position: absolute;
}
.top__card {
  padding: 1rem 4rem;
  height: 8rem;
  border-top-right-radius: 9px;
  border-top-left-radius: 9px;
}
.topcard-score {
  background: linear-gradient(105deg, #f5faff -3.24%, #a5d3ff 105.76%);
}
.topcard-hots {
  background: linear-gradient(105deg, #f5faff -3.24%, #ff9d44 105.76%);
}
.topcard-wit {
  background: linear-gradient(105deg, #f5faff -3.24%, #a990ec 105.76%);
}
.topcard-math {
  background: linear-gradient(105deg, #f5faff -3.24%, #96d3d2 105.76%);
}
.topcard-english {
  background: linear-gradient(105deg, #f5faff -3.24%, #dea3c0 105.76%);
}
.bottam__card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1rem 4rem;
}
.examTitle {
  color: var(--Grayscale-Title-Active, #14142b);
  font-feature-settings: "clig" off, "liga" off;

  /* Mobile/Subtitle 1 - SB */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 00;
  line-height: 24px; /* 150% */
}
.attempt-btn {
  display: flex;
  width: 311px;
  height: 44px;
  padding: 8px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 40px;
  background: var(--Primary-Default, #3c8dcb);
  color: var(--Grayscale-Off-white, #fcfcfc);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;
  border-width: 0;
  /* Mobile/Button - SB */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.25px;
}
.group-cont {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.group-des {
  color: var(--Grayscale-Body, #4e4b66);
  font-feature-settings: "clig" off, "liga" off;
  margin-left: 5px;

  /* Mobile/Button - SB */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.25px;
}
.status-icon {
  width: 6px;
  height: 6px;
}
.staus-display {
  padding: 5px;
  border-radius: 20px;
  border: 1px solid var(--Grayscale-Line, #d9dbe9);
  background: var(--Grayscale-Off-white, #fcfcfc);
  display: inline-flex;
  padding: 6px 16px;
  align-items: center;
  gap: 8px;
  color: red;
  font-weight: 500;
}
.complete-test-card {
  width: 940px;
  /* height: 210px; */
  margin-bottom: 2rem;
}
.complete-heading {
  color: var(--Grayscale-Title-Active, #14142b);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  letter-spacing: 0.75px;
}
.reports-cont {
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
.report-cards {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: var(--Secondary-Darkmode, #ffed9d);
  width: 300px;
  height: 129px;
  flex-shrink: 0;
  padding: 0.7rem;
}
.report-time {
  color: var(--Grayscale-Title-Active, #14142b);
  font-feature-settings: "clig" off, "liga" off;

  /* Mobile/Body 2 - M */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.report-score {
  color: var(--Grayscale-Title-Active, #14142b);
  font-feature-settings: "clig" off, "liga" off;

  /* Mobile/Button - SB */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.25px;
}
.view-cta {
  display: inline-flex;
  height: 44px;
  padding: 8px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 40px;
  margin-left: auto;
  border: 1px solid var(--Primary-Default, #3c8dcb);
  background-color: transparent;
  color: var(--Primary-Default, #3c8dcb);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Mobile/Button - SB */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 128.571% */
  letter-spacing: 0.25px;
}
.view-more {
  display: flex;
  width: 940px;
  flex-shrink: 0;
  height: 40px;
  justify-content: center;
  align-items: center;
  background: var(--Grayscale-Off-white, #fcfcfc);
  margin: 1rem 0rem;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-Line, #d9dbe9);
  color: var(--grayscale-label-icons-grey, #6e7191);
  text-align: right;
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px; /* 228.571% */
}

.reword-head {
  color: var(--Grayscale-Title-Active, #14142b);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  letter-spacing: 0.75px;
}
.reword-hiligt {
  color: var(--Primary-Default, #3c8dcb);
}
.reword-des {
  color: var(--Grayscale-Body, #4e4b66);
  font-feature-settings: "clig" off, "liga" off;

  /* Mobile/Body 1 - R */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.certificate-cont {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-bottom: 2rem;
}
.certificate-card {
  display: flex;
  width: 221px;
  padding: 28px 28px 15px 27px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 20px;
  border: 1px solid var(--Grayscale-Line, #d9dbe9);
  background: var(--Grayscale-Background, #f7f7fc);
}
.certificate-card img {
  width: 70%;
  margin-bottom: auto;
}
.certificet-name {
  color: var(--Grayscale-Title-Active, #14142b);
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Desktop/Link Small */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  letter-spacing: 0.75px;
}
.mock-section {
  display: flex;
  justify-content: space-between;
  width: 940px;
  padding: 10px 13px;
  align-items: center;
  border-radius: 8px;
  border: 0.5px solid var(--Secondary-Default, #fbd323);
  background: var(--Secondary-Light, #fff7d4);
}
.mock-text {
  color: var(--Grayscale-Title-Active, #14142b);
  font-feature-settings: "clig" off, "liga" off;

  /* Desktop/Link Small */
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 175% */
  letter-spacing: 0.75px;
}
.mock-btn {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid var(--Primary-Default, #3c8dcb);
  color: var(--Primary-Default, #3c8dcb);
  font-feature-settings: "clig" off, "liga" off;

  /* Mobile/Body 2 - M */
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
}
.text-cont {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

@media screen and (min-width: 320px) and (max-width: 760px) {
  .banner-img {
    width: 340px;
    transform: scale(3);
    transform-origin: 252px 1px;
  }

  .banner {
    width: 100%;
    overflow: hidden;
  }
  .logo-img {
    top: 2.5rem;
    width: 117px;
    height: 73px;
    flex-shrink: 0;
  }
  .card-des {
    color: var(--Grayscale-Off-white, #fcfcfc);
    font-feature-settings: "clig" off, "liga" off;
    bottom: 1rem;
    width: 14rem;

    /* Mobile/Subtext - R */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px; /* 128.571% */
  }
  .illutionCont {
    width: 294.335px;
    height: 280.768px;
    transform: rotate(165deg);
    flex-shrink: 0;
  }
  .arrowLeft {
    position: absolute;
    top: 0px;
    left: 20px;
    width: 2rem;
    height: 1rem;
    flex-shrink: 0;
    margin-top: 2rem;
    display: block;
  }

  .ellis-l {
    top: 1rem;
    right: 0.5rem;
  }
  .ellis-r {
    top: 2.8rem;
    right: 5rem;
  }
  .AttemptCard {
    width: auto;
    height: 217px;
    margin: 15px;
  }
  .doto-illution {
    width: 139.339px;
    height: 145px;
    flex-shrink: 0;
  }
  .top__card {
    width: 100%;
    height: 132px;
    flex-shrink: 0;
    padding: 1rem;
  }
  .bottam__card {
    padding: 10px;
    justify-content: center;
  }
  .examTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px; /* 150% */
  }
  .staus-display {
    display: none;
  }
  .attempt-btn {
    width: 280px;
    height: 44px;
    padding: 8px 24px;
  }

  .group-des {
    color: var(--Grayscale-Body, #4e4b66);
    font-feature-settings: "clig" off, "liga" off;

    /* Mobile/Button - SB */
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px; /* 128.571% */
    letter-spacing: 0.25px;
  }
  .complete-test-card {
    width: 100%;
    /* height: 114px; */
    flex-shrink: 0;
    padding: 1rem;
    margin-bottom: 0px;
  }
  .report-cards {
    width: 100%;
  }
  .complete-heading {
    color: var(--Grayscale-Title-Active, #14142b);
    font-feature-settings: "clig" off, "liga" off;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px; /* 175% */
    letter-spacing: 0.75px;
  }
  .reports-cont {
    flex-direction: column;
    /* padding: 1rem; */
  }
  .report-time {
    font-size: 12px;
  }
  .report-score {
    font-size: 12px;

    line-height: 16px; /* 128.571% */
  }
  .view-cta {
    padding: 8px 24px;
    height: 33px;
  }
  .view-more {
    width: 100%;
    height: 40px;
  }

  .reword-head {
    font-size: 14px;

    line-height: 18px; /* 175% */
    letter-spacing: 0.25px;
  }
  .reword-des {
    font-size: 11px;
    font-weight: 500;
    line-height: 14px;
    margin-bottom: 10px !important;
  }
  .mock_img {
    width: 25px;
  }
  .certificate-card {
    width: 92px;

    padding: 11.303px 9.5px 10.641px 9.5px;
  }
  .certificate-card img {
    width: 100%;
    height: auto;
    margin-bottom: auto;
  }

  .certificet-name {
    color: var(--Grayscale-Title-Active, #14142b);
    text-align: center;
    font-feature-settings: "clig" off, "liga" off;

    /* Desktop/Link Small */
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px; /* 175% */
    letter-spacing: 0.5px;
  }
  .mock-section {
    width: auto;
    padding: 10px;
    margin: 15px;
  }
  .mock-text {
    width: 120px;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
  .mock-btn {
    width: 9rem;
    padding: 10px 12px;
  }
  .text-cont {
    gap: 0.2rem;
  }
}

.pl_header-heading {
  color: var(--White, #f2f2f2);

  font-family: Montserrat;

  font-size: 16px;

  font-style: normal;

  font-weight: 400;

  line-height: normal;

  margin-top: 0.6rem;
}
.planerLine {
  opacity: 0.4;
  background: #bbb;
  width: 100%;
  height: 0.6px;
  align-self: center;
}
.pl_duration-cont {
  display: flex;

  padding: 8px 12px;

  align-items: center;

  gap: 4px;

  border-radius: 20px;

  background: var(
    --Radial,
    radial-gradient(174.64% 160.39% at 50.4% 58%, rgba(255, 249, 215, 0.21) 0%, rgba(255, 255, 255, 0.11) 100%)
  );

  color: #fff;
}

.planer_exam-cards-cont {
  display: flex;

  flex-direction: row;

  gap: 1rem;

  margin-top: 1rem;

  flex-wrap: wrap;
}

.pl_test-cta {
  display: flex;

  width: 100%;

  padding: 13px 12px;

  justify-content: center;

  align-items: center;

  gap: 4px;

  border-radius: 36px;

  border: 1px solid var(--Bck, #edcd6a);

  color: var(--Bck, #edcd6a);

  text-align: center;

  font-family: Montserrat;

  font-size: 16px;

  font-style: normal;

  font-weight: 600;

  line-height: 24px; /* 150% */

  background-color: transparent;
}

.group-text {
  opacity: 0.7;
}

.pl_live-img {
  margin-bottom: 2px;
}

.pl_group-info {
  color: var(--Grayscale-Off-white, #fcfcfc);

  font-feature-settings: "clig" off, "liga" off;

  font-family: Montserrat;

  font-size: 14px;

  font-style: normal;

  font-weight: 600;

  line-height: 18px; /* 128.571% */

  letter-spacing: 0.25px;

  display: flex;
}

.pl_card-details {
  color: var(--White, #f2f2f2);

  font-feature-settings: "clig" off, "liga" off;

  /* Mobile/Body 1 - R */

  font-family: Montserrat;

  font-size: 16px;

  font-style: normal;

  font-weight: 400;

  line-height: 24px; /* 150% */

  display: flex; /* Use flexbox */

  align-items: center;
}

.pl_card-details span {
  margin-bottom: 1px;

  margin-right: 8px; /* Add margin for spacing between image and text */
}

.pl_card-header-cont {
  display: flex;
  min-height: 2rem;
  width: 100%;

  justify-content: space-between !important;
}

.pl_status-cont {
  display: flex;

  padding: 4px 12px;

  align-items: center;

  gap: 4px;

  border-radius: 20px;

  background: var(
    --Radial,
    radial-gradient(174.64% 160.39% at 50.4% 58%, rgba(255, 249, 215, 0.21) 0%, rgba(255, 255, 255, 0.11) 100%)
  );

  color: var(--White, #f2f2f2);

  font-family: Montserrat;

  font-size: 12px;

  font-style: normal;

  font-weight: 700;

  line-height: 20px; /* 166.667% */
}

.pl_exam-title {
  color: var(--White, #f2f2f2);

  font-feature-settings: "clig" off, "liga" off;

  /* Mobile/Bold title */

  font-family: Montserrat;

  font-size: 20px;

  font-style: normal;

  font-weight: 700;

  line-height: 28px; /* 140% */
}

.Planer-evnt-card {
  display: flex;

  width: 32%;

  padding: 16px 14px;

  flex-direction: column;

  align-items: flex-start;

  gap: 24px;

  border-radius: 10px;

  border: 0.5px solid var(--Test-Stroke-All-Test, #3c74c4);

  background: var(--Test-All-test, #003b8f);

  /* Test Card Shadow */

  box-shadow: 0px 169px 47px 0px rgba(0, 0, 0, 0), 0px 108px 43px 0px rgba(0, 0, 0, 0.03),
    0px 61px 36px 0px rgba(0, 0, 0, 0.09), 0px 27px 27px 0px rgba(0, 0, 0, 0.16), 0px 7px 15px 0px rgba(0, 0, 0, 0.18);
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  .planer_exam-cards-cont {
    flex-direction: column;

    gap: 1rem;

    margin-top: 1rem;
  }
}

.test-heading {
  color: #fff;
  font-feature-settings: "clig" off, "liga" off;

  /* Mobile/Bold title */
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 140% */
}
.event-type-cont {
  display: flex;
  gap: 1rem;
}
.events-type {
  color: var(--White, #f2f2f2);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  opacity: 0.85;
}
.selected {
  color: var(--Bck, #edcd6a);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 100%;
  text-decoration: underline;
  text-underline-offset: 0.6rem;
}
.SCRPT-icnhdng {
  font-size: 13px;
  font-weight: 500;
  line-height: 1.2;
  color: #6e7191;
  padding: 0px;
  margin: 0px 0px 5px 0px;
}
.SCRPT-scrtmr-blck .SCRPT-statbx-icon-info .SCRPT-icnhdng {
  margin: 0px 0px 5px 0px;
  white-space: nowrap;
}
.SCRPT-icnhdng.score-red-text {
  color: #b7295a !important;
}
.SCRPT-cunt {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
  color: #4e4b66;
  margin: 0px;
  padding: 0px;
}
.SCRPT-statbx-icon-info .SCRPT-cunt .light {
  color: rgba(30, 42, 54, 0.4);
}
.SCRPT-statbx-icon-info .SCRPT-cunt {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.2;
  color: rgba(30, 42, 54, 1);
  margin: 0px;
  padding: 0px;
}
.SCRPT-elips {
  max-width: 165px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.perform_box {
  border-radius: 16px;
  border: 1px solid var(--secondary-darkmode, #ffed9d);
  background: var(--secondary-light, #fff7d4);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  margin-bottom: 20px;
}
.perform_webflex {
  display: flex;
  gap: 6px;
  align-items: center;
}
.perform_mobflex {
  display: flex;
  width: 315px;
  align-items: center;
  gap: 5px;
}
.no_mb {
  margin-bottom: 0px;
}
.pl_header-cont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 6px;
}
@media screen and (min-width: 980px) and (max-width: 1360px) {
  .Planer-evnt-card {
    width: 50%;
  }
}
@media screen and (min-width: 760px) and (max-width: 980px) {
  .Planer-evnt-card {
    width: 100%;
  }
}
@media screen and (min-width: 320px) and (max-width: 760px) {
  .banner-img {
    width: 340px;
    transform: scale(3);
    transform-origin: 252px 1px;
  }

  .Planer-evnt-card {
    width: 100%;
  }
  .pl_test-cta {
    width: 100%;
    background: var(--Linear, linear-gradient(91deg, #b6a238 0.48%, #eddc82 44.37%, #a89637 99.62%));
    color: var(--Button-Text, #3c3306);
  }
  .mob_dashboard {
    margin-top: 1.5rem;
  }
  .test-heading {
    margin: 1rem 0rem;
  }
}
@media only screen and (min-width: 320px) and (max-width: 780px) {
  .SCRPT-icnhdng {
    font-size: 12px;
    line-height: 20px;
  }
  .SCRPT-cunt {
    font-size: 18px;
  }
  .perform_box {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    padding: 30px;
  }
  .perform_webflex {
    gap: 20px;
  }
  .perform_mobflex {
    display: block;
    width: 300px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 576px) {
  .SCRPT-cunt {
    font-size: 30px;
  }
  .perform_webflex {
    gap: 20px;
  }
  .perform_box {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    padding: 30px;
  }
  .perform_mobflex {
    display: block;
    width: 300px;
  }
}

/*Advance report*/

.AdRp-main-cont {
  display: flex;
  width: 100%;
  padding: 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 10px;
  border: 1px solid var(--card-stroke, #e7e8f1);
  background: #fff;
  margin-top: 1rem;

  /* card shadow */
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
}
.AdRp-header-cont {
  display: flex;
  width: 100%;
  color: var(--grayscale-label-icons-grey, #6e7191);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 21px */
}
.AdRp-bodyitems {
  display: flex;
  width: 100%;
  height: 2.5rem;
}
.AdRp-bodyitems1 {
  width: 40%;
  display: flex;
  flex-direction: column;
}
.AdRp-bodyitems2 {
  display: flex;
  width: 10%;
}
.AdRp-bodyitems3 {
  display: flex;
  width: 30%;
  gap: 0.8rem;
}
.AdRp-bodyitems4 {
  display: flex;
  width: 20%;
}

.AdRp-bodyitems1 h3 {
  color: var(--Grayscale-Title-Active, #14142b);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
}
.AdRp-bodyitems1 p {
  color: #ff5353;
  font-family: Montserrat;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 15px */
}
.AdRp-header-contp1 {
  width: 38%;
}
.AdRp-header-contp2 {
  width: 10%;
}
.AdRp-header-contp3 {
  width: 32%;
}
.AdRp-header-contp4 {
  width: 20%;
}
.AdRp-bodyitems3 img {
  width: 20px;
  height: 20px;
}
.AdRp-bodyitems3 p {
  color: var(--Grayscale-Title-Active, #14142b);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
  width: 1rem;
}
.AdRp-bodyitems4 p {
  color: var(--Grayscale-Title-Active, #14142b);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  margin-left: 25%;
}
.AdRp-bodyitems4 span {
  font-weight: 600 !important;
}
.AdRp-main-contMob {
  display: none;
}
.examlivemob {
  display: none;
}

.examliveweb {
  display: block;
}
@media only screen and (min-width: 320px) and (max-width: 786px) {
  .exam-titleCont {
    display: flex;
    justify-content: space-between;
  }
  .examliveweb {
    display: none;
  }

  .staus-display {
    padding: 5px;
    border-radius: 20px;
    border: 1px solid var(--Grayscale-Line, #d9dbe9);
    background: var(--Grayscale-Off-white, #fcfcfc);
    display: inline-flex;
    padding: 1px 5px;
    align-items: center;
    gap: 4px;
    color: red;
    font-weight: 400;
    font-size: 12px;
  }

  .examlivemob {
    display: block;
  }
  .AdRp-inMob-cont {
    border-top: 1px solid #e7e8f1;
    width: 100%;
  }
  .AdRp-bodyitems1 h4 {
    color: var(--grayscale-label-icons-grey, #6e7191);
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%; /* 18px */
  }
  .AdRp-bodyitems1 h3 {
    line-height: 50%;
  }
  .AdRp-marksCont {
    display: flex;
    gap: 1rem;
    text-align: center;
  }
  .AdRp-marksCont p {
    color: var(--Grayscale-Title-Active, #14142b);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
  }
  .AdRp-marksCont span {
    font-weight: 600;
  }
  .AdRp-bodyitems1 {
    width: 100%;
  }
  .AdRp-header-cont {
    gap: 7rem;
    margin-top: 1rem;
  }
  .AdRp-bodyitems {
    gap: 8rem;
  }
  .AdRp-main-cont {
    display: none;
  }
  .AdRp-main-contMob {
    display: flex;
    width: 100%;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 10px;
    border: 1px solid var(--card-stroke, #e7e8f1);
    background: #fff;
    margin-top: 1rem;

    /* card shadow */
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  }
  .AdRp-mob-epandIcons {
    margin-left: auto;
  }
}

@media only screen and (min-width: 320px) and (max-width: 780px) {
  .SCRPT-icnhdng {
    font-size: 12px;
    line-height: 20px;
  }
  .SCRPT-cunt {
    font-size: 18px;
  }
  .perform_box {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    padding: 30px;
  }
  .perform_webflex {
    gap: 20px;
  }
  .perform_mobflex {
    display: block;
    width: 300px;
  }
  .membership__card2 {
    width: 100%;
    margin-top: 0;
    margin: 0;
    margin-left: 0px;
  }
}
@media only screen and (min-width: 481px) and (max-width: 576px) {
  .SCRPT-cunt {
    font-size: 30px;
  }
  .perform_webflex {
    gap: 20px;
  }
  .perform_box {
    grid-template-columns: repeat(1, 1fr);
    gap: 25px;
    padding: 30px;
  }
  .perform_mobflex {
    display: block;
    width: 300px;
  }
}
