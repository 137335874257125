/* All existing styles up to media query */
.talk-to-expert-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(8, 14, 20, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.talk-to-expert-container {
  background: white;
  border-radius: 20px;
  position: relative;
  width: 60vw;
  height: 80vh;
  overflow: hidden;
  display: flex;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.talk-to-expert-left {
  flex: 1;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.talk-to-expert-left img {
  width: auto;
  height: 100%;
  object-fit: cover;
}

.talk-to-expert-right {
  flex: 1;
  padding: 35px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.talk-to-expert-right::-webkit-scrollbar {
  width: 4px;
}

.talk-to-expert-right::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.talk-to-expert-right::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.talk-to-expert-right::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.talk-to-expert-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  z-index: 1;
}

.talk-to-expert-header {
  text-align: center;
  margin-bottom: 30px;
}

.talk-to-expert-logo {
  max-width: 200px;
  margin-bottom: 20px;
}

.talk-to-expert-title h1 {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 10px;
  color: #333;
}

.talk-to-expert-title h2 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  color: #333;
}

.talk-to-expert-title p {
  font-size: 18px;
  color: #007bff;
}

.talk-to-expert-steps {
  display: flex;
  justify-content: center;
  gap: 60px;
  position: relative;
  padding: 0 20px;
}

.talk-to-expert-steps::before {
  content: "";
  position: absolute;
  top: 15px;
  left: 80px;
  right: 80px;
  height: 2px;
  background: #e0e0e0;
  z-index: 0;
}

.talk-to-expert-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
  min-width: 100px;
}

.talk-to-expert-step-number {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: white;
  border: 2px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 14px;
  color: #666;
  position: relative;
  z-index: 1;
}

.talk-to-expert-step.active .talk-to-expert-step-number {
  background: #007bff;
  color: white;
  border-color: #007bff;
}

.talk-to-expert-step-text {
  font-size: 14px;
  color: #666;
  text-align: center;
}

.talk-to-expert-step.active .talk-to-expert-step-text {
  color: #007bff;
  font-weight: 500;
}

.talk-to-expert-content {
  padding: 20px 0;
}

.talk-to-expert-date-selection {
  margin-top: 20px;
}

.talk-to-expert-date-selection h3 {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 15px;
}

.talk-to-expert-date-options {
  display: flex;
  gap: 8px;
  margin-bottom: 25px;
  width: 100%;
}

.talk-to-expert-date-option {
  flex: 1;
  cursor: pointer;
}

.talk-to-expert-date-option input[type="radio"] {
  display: none;
}

.talk-to-expert-date-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  border: 1px solid #9c9fa1;
  border-radius: 12px;
  transition: all 0.2s;
  position: relative;
}

.talk-to-expert-date-option input[type="radio"]:checked + .talk-to-expert-date-label {
  border: 1px solid #06aa2f;
  background: rgba(214, 244, 222, 0.5);
}

.talk-to-expert-date-option.disabled .talk-to-expert-date-label {
  background: #f5f5f5;
  border-color: #e0e0e0;
  cursor: not-allowed;
  opacity: 0.7;
}

.talk-to-expert-date-option.disabled .talk-to-expert-day,
.talk-to-expert-date-option.disabled .talk-to-expert-date {
  color: #999;
}

.slots-full-text {
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  color: #ff4d4f;
  font-size: 12px;
  font-weight: 500;
}

.talk-to-expert-day {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-bottom: 4px;
}

.talk-to-expert-date {
  font-size: 14px;
  font-weight: 600;
}

.talk-to-expert-time-options {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 25px;
}

.talk-to-expert-time-option {
  flex: 0 0 calc(20% - 10px);
  cursor: pointer;
}

.talk-to-expert-time-option input[type="radio"] {
  display: none;
}

.talk-to-expert-time-option span {
  display: block;
  text-align: center;
  padding: 10px;
  border: 1px solid #9c9fa1;
  border-radius: 8px;
  transition: all 0.2s;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

.talk-to-expert-time-option input[type="radio"]:checked + span {
  border: 1px solid #06aa2f;
  background: rgba(214, 244, 222, 0.5);
}

.talk-to-expert-continue {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 16px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s;
  text-transform: capitalize;
}

.talk-to-expert-continue:hover:not(:disabled) {
  background: #0056b3;
}

.talk-to-expert-continue:disabled {
  opacity: 0.3;
  background: #007bff;
  box-shadow: 0px 7px 7px 0px rgba(0, 123, 255, 0.12);
  cursor: not-allowed;
}

.talk-to-expert-content h2 {
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #080e14;
}

.talk-to-expert-subtitle {
  text-align: left;
  color: #52565b;
  margin-bottom: 30px;
  font-size: 14px;
  font-weight: 400;
}

.talk-to-expert-attendees {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 20px;
  max-height: 250px;
  overflow-y: auto;
  padding-right: 5px;
}

.talk-to-expert-attendees::-webkit-scrollbar {
  width: 4px;
}

.talk-to-expert-attendees::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.talk-to-expert-attendees::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.talk-to-expert-attendees::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.talk-to-expert-attendee {
  display: flex;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #e0e0e0;
  transition: all 0.2s;
}

.talk-to-expert-attendee:hover {
  border-color: #06aa2f;
}

.talk-to-expert-attendee.attendee-selected {
  background: rgba(214, 244, 222, 0.5);
  border-color: #06aa2f;
}

.talk-to-expert-attendee input[type="checkbox"] {
  display: none;
}

.talk-to-expert-attendee span {
  font-size: 16px;
  color: #333;
  margin-left: 12px;
  flex: 1;
}

.talk-to-expert-finish {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: auto;
  padding: 16px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 30px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background 0.2s;
  text-transform: capitalize;
}

.talk-to-expert-finish .talk-to-expert-arrow {
  margin-left: 8px;
  font-size: 20px;
}

.talk-to-expert-finish:hover:not(:disabled) {
  background: #0056b3;
}

.talk-to-expert-finish:disabled {
  background: #e0e0e0;
  cursor: not-allowed;
}

.talk-to-expert-success {
  flex: 1;
  padding: 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.success-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  margin-top: 40px;
}

.success-content .success-gif {
  width: 300px;
  height: 300px;
  object-fit: contain;
  margin-bottom: 40px;
}

.success-content h2 {
  font-size: 24px;
  font-weight: 700;
  color: #080e14;
  max-width: 400px;
  line-height: 36px;
  text-align: center;
}

@media (max-width: 768px) {
  .talk-to-expert-wrapper {
    align-items: flex-start;
  }

  .talk-to-expert-wrapper.success-step {
    align-items: center;
  }

  .talk-to-expert-container {
    width: 100%;
    height: 100%;
    border-radius: 0;
    flex-direction: column;
  }

  .talk-to-expert-container.success-container {
    width: 90%;
    height: auto;
    max-width: 400px;
    border-radius: 20px;
    margin: 20px;
  }

  .talk-to-expert-left {
    display: none;
  }

  .talk-to-expert-right {
    padding: 15px;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .talk-to-expert-content {
    margin-top: 40px;
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
  }

  .talk-to-expert-content h2 {
    font-size: 20px;
    margin-bottom: 8px;
  }

  .talk-to-expert-subtitle {
    margin-bottom: 20px !important;
  }

  .talk-to-expert-date-selection {
    margin-top: 0;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .talk-to-expert-date-options {
    margin-bottom: 0;
  }

  .talk-to-expert-time-options {
    margin-bottom: 0;
  }

  .talk-to-expert-time-option span {
    padding: 15px;
  }

  .talk-to-expert-close {
    top: 0;
    right: 0;
  }

  .talk-to-expert-steps {
    margin-bottom: 20px;
    gap: 40px;
    padding: 0 10px;
  }

  .talk-to-expert-steps::before {
    left: 50px;
    right: 50px;
  }

  .talk-to-expert-step {
    min-width: 80px;
  }

  .talk-to-expert-step-text {
    font-size: 12px;
  }

  .talk-to-expert-continue {
    margin-top: auto;
    padding: 12px;
  }

  .talk-to-expert-attendees {
    max-height: none;
    margin-bottom: 15px;
  }

  .success-content .success-gif {
    width: 200px;
    height: 200px;
    margin-bottom: 30px;
  }

  .success-content h2 {
    font-size: 18px;
  }

  .talk-to-expert-success {
    padding: 20px;
  }

  .success-content {
    margin-top: 20px;
  }
}
